header {

    border-top: 2px solid var(--primary-rose-red);

    div.primary-menu-wrapper {
        max-width: 1000px;

        .btn__secondary_sm {
            height: 50px;

            &:hover {
                color: var(--white);
            }
        }

        .icons a {
            text-decoration: none;
        }

    }

    ul.primary-menu-links {
        display: flex;
        gap: 1.25rem;
        list-style: none;

        li {
            margin-right: 0;
            font-size: 18px;

        }

        a {
            text-decoration: none;
            color: var(--primary-dark-grey);
            transition: .1s;

            &:hover {
                color: var(--primary-rose-red);
                border-bottom: 1px solid var(--primary-rose-red);
                padding-bottom: 0.5rem;
                transition: .1s;
            }
        }


        .current-menu-item {
            a {
                color: var(--primary-rose-red);
                border-bottom: 1px solid var(--primary-rose-red);
                padding-bottom: 0.5rem;
            }
        }
    }


    // SVG LOGO DESKTOP STYLE
    svg.logo-desktop {

        cursor: pointer;
        overflow: visible;

        &>*,
        g {
            transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
        }

        g.group-33 {
            transform: translate(78.042px);
        }

        g.group-9 {
            transform: translate(26.42px, 45.264px);
        }

        g.group-10 {
            transform: translate(0px, 26.256px);
        }

        g.group-11 {
            transform: translate(37.489px, -3.000px);
        }

        g.group-12 {
            transform: translate(45.429px, 33.734px);
        }

        g.group-13 {
            transform: translate(44.045px, 14.923px);
        }


        &:hover {

            path.path-11 {
                transform: matrix(0.996, -0.087, 0.087, 0.996, -11.446, -76.266);
            }

            g.group-33 {
                transform: translate(78.966px, 5px);
            }

            g.group-9 {
                transform: translate(26.42px, 41.657px);
            }

            g.group-10 {
                transform: translate(5px, 26.256px);
            }

            g.group-11 {
                transform: translate(37.489px, -6.077px);
            }

            g.group-12 {
                transform: translate(39.429px, 33.734px);
            }

            g.group-13 {
                transform: translate(50.045px, 10.923px);
            }
        }
    }

    svg.main-nav-icon {
        fill: var(--primary-dark-grey);
        padding: 2px;
        cursor: pointer;
        overflow: visible;

        path {
            transition: .1s;
        }

        &:hover {
            fill: var(--primary-rose-red);


            .Path_99 {
                transform: rotate(8deg) translate(1px, -2px);
            }

            .Path_98 {
                transform: rotate(-8deg) translate(-4px, 2px);
            }
        }
    }

    img.mobile-logo {
        width: 50%;
    }

    @media screen and (max-width:768px) {
        img.mobile-logo {
            width: 40%;
        }

        svg.main-nav-icon {
            padding: 4px;
            margin-top: 12px;
        }
    }

}


// BURGER MENU STYLE
div.burger-menu-overlay {
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 110;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--body-overlay);
    visibility: hidden;
    opacity: 0;
    transform: scale(1.09);
    transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
    box-sizing: border-box;


    &.active {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
    }

    img.applaus-logo-white {
        width: 30%;
        margin: 1rem 0 0 1rem;

        @media screen and (max-width: 768px) {
            width: 20%;
        }

        @media screen and (max-width: 576px) {
            width: 30%;
        }
    }

    div.burger-menu-wrapper {
        background-color: var(--primary-rose-red);
        height: min-content;

        ul {
            list-style: none;
            padding: 0;
        }

        a {
            color: var(--white);
            text-decoration: none;

        }

        .btn__secondary_sm {
            border: 1px solid var(--white);
            width: auto;
            padding-inline: 20px;

            &::after {
                filter: brightness(0) invert(1);
            }
        }

        div.sidebar-some-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 10px;
        }
    }

    div.menu-primary {
        a {
            font-size: 2rem;
        }
    }

    div.menu-secondary {
        a {
            font-size: 1.5rem;
        }
    }

    div.btn-close-menu {
        svg {
            cursor: pointer;
            transition: .2s;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}