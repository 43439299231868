:root {
  --white: #fff;
  --primary-rose-red: #e1295b;
  --primary-pink: #FBE0E8;
  --primary-purple-night: #5700fa;
  --primary-blue-green: #1fc4bb;
  --primary-dark-grey: #2b2626;
  --primary-whisp-pink: #fef4f2;
  --primary-light-grey: #efefef;
  --secondary-saffron-yellow: #f0c02b;
  --secondary-apple-green: #4FBA4F;
  --secondary-green-sheen: #75B1B1;
  --secondary-pink: #FBE0E8;
  --secondary-dark-blue: #30297F;
  --grey-text: #343434;
  --grey-text-light: #2B2626CC;
  --body-overlay: #2B262670;
  --border-line: #707070;
  --dark-magenta: #8B008B;
  --medium-violet-red: #C71585;
  --hot-pink: #FF69B4;



  --box-shadow-dark: 0px 23px 40px #2B262633;
  --box-shadow-purple: 0px 23px 40px #5700fa33;
  --box-shadow-red: 0px 23px 40px #E1295B33;
  --box-shadow-green: 0px 23px 40px #2B262633;
  --box-shadow-dark-blue: 0px 23px 40px #30297F33;
  --box-shadow-form: 0px 23px 40px #00000029;
  --box-shadow-literature: 0px 23px 40px #0000001A;
}