section.meet-applaus{

    // CSS for the team member card
    div.team-member-card{
        position: relative;

        &:hover .overlay{
            height: 52%;
        }
    }

    img.image{
        object-fit: cover;
        object-position: center;
    }

    div.overlay{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--primary-dark-grey);
        overflow: hidden;
        width: 100%;
        height: 5rem;
        transition: .3s ease;

        .btn__primary_sm:hover{
            background-color: transparent;
            border: 1px solid var(--white);
            color: var(--white);

            &::after{
                filter: brightness(0) invert(1);
            }
        }
    }

    div.content{
        position: absolute;
        width: 100%;
        top: 46%;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -60%);
        text-align: center;
        color: var(--white);

    }

    div.content-header{
        text-transform: uppercase;
        min-height: 80px;

        .job-title{
            opacity: .8;
        }

        .name{
            font-size: 1.2rem;
        }
    }

    div.cta-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }

    .contact-info a{
        text-decoration: none;
        color: var(--white);

        &:hover{
            color: var(--white);
        }
    }

    .contact-info.tel a:before,
    .contact-info.email a:before{
        float: left;
        content: "";
        background-size: cover;
        position: relative;
        height: 16px;
        margin-right: 8px;
        margin-top: 4px;
    }

    .contact-info.tel a:before{
        background-image: url(../img/icons/circle-phone-flip.svg);
        width: 16px;
        filter: brightness(0) invert(1);
    }

    .contact-info.email a:before{
        background-image: url(../img/icons/envelope.svg);
        width: 17.5px;
        filter: brightness(0) invert(1);
    }


    @media screen and (max-width: 1400px){

        div.team-member-card{
            position: relative;
    
            &:hover .overlay{
                height: 80%;
            }
        }

    }


    @media screen and (max-width: 1200px){

        div.team-member-card{
            position: relative;
    
            &:hover .overlay{
                height: 100%;
            }
        }

        div.content{
            svg{
                margin-right: 8px;
            }
        }


        div.content-header{

            .name, .job-title{
                font-size: .9rem;
            }
        }

    }


    @media screen and (max-width:576px){

        div.post-item-grid{
            // grid-template-columns: auto;
        }

        div.team-member-card{
            position: relative;
        }

        div.content-header{
            .name {
                font-size: .7rem;
            }
            .job-title{
                font-size: .5rem;
            }
        }

        .btn__primary_sm {
            width: 150px;
            height: 50px;
        }

    }

}

// CSS for the modal

div.modal.custom{

    backdrop-filter: blur(9px);
    background-color: var(--body-overlay);

    div.modal-dialog-wrapper{
        height: 80%;
    }
  
    div.modal-dialog{
        min-width: 70%;
        margin: 4% auto;
        min-height: 60%;
        max-height: 82%;
    }

    div.modal-content{
        border-radius: 0;
        border: none;

    }

    img.team-member-image{
        height: 100%;
        object-fit: cover;
    }

    .contact-info.tel a,
    .contact-info.email a{
        width: auto;
        padding-inline: 20px;

        &::before{
            float: left;
            content: "";
            background-size: cover;
            position: relative;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            margin-top: 4px;
            filter: brightness(0) invert(1);
        }

        &:hover::before{
            filter: unset;
        }
    }  

    .contact-info.tel a:before{
        background-image: url(../img/icons/circle-phone-flip.svg);
        width: 16px;
    }

    .contact-info.email a:before{
        background-image: url(../img/icons/envelope.svg);
        width: 17.5px;
    }

    .contact-info.tel a:after,
    .contact-info.email a:after{
        width: unset;
    }


    @media screen and(max-width: 992px){
        div.modal-dialog-wrapper{
            height: 100%;
        }

        div.modal-dialog{
            margin: 0;
            min-width: 100%;
            min-height: 100%;
        }

        div.modal-content{
            overflow: visible;
            min-height: 100%;
        }

        img.team-member-image{
            width: 30%;
            padding-bottom: 1rem;
        }
    }


    @media screen and(max-width: 576px){

        img.team-member-image{
            width: 60%;
        }
    }
}