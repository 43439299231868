/*!
Theme Name: Twentyfour
Theme URI: https://twentyfour.dk/
Author: Twentyfour ApS
Author URI: https://twentyfour.dk/
Description: Custom theme: Twentyfour, developed by Twentyfour ApS
Version: 1.0.0
Tested up to: 5.4
Requires PHP: 5.6
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: twentyfour-parent
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned.

Twentyfour is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Generic
	- Normalize
	- Box sizing
# Base
	- Typography
	- Elements
	- Links
	- Forms
## Layouts
# Components
	- Navigation
	- Posts and pages
	- Comments
	- Widgets
	- Media
	- Captions
	- Galleries
# plugins
	- Jetpack infinite scroll
# Utilities
	- Accessibility
	- Alignments

--------------------------------------------------------------*/
:root {
  --white: #fff;
  --primary-rose-red: #e1295b;
  --primary-pink: #FBE0E8;
  --primary-purple-night: #5700fa;
  --primary-blue-green: #1fc4bb;
  --primary-dark-grey: #2b2626;
  --primary-whisp-pink: #fef4f2;
  --primary-light-grey: #efefef;
  --secondary-saffron-yellow: #f0c02b;
  --secondary-apple-green: #4FBA4F;
  --secondary-green-sheen: #75B1B1;
  --secondary-pink: #FBE0E8;
  --secondary-dark-blue: #30297F;
  --grey-text: #343434;
  --grey-text-light: #2B2626CC;
  --body-overlay: #2B262670;
  --border-line: #707070;
  --dark-magenta: #8B008B;
  --medium-violet-red: #C71585;
  --hot-pink: #FF69B4;
  --box-shadow-dark: 0px 23px 40px #2B262633;
  --box-shadow-purple: 0px 23px 40px #5700fa33;
  --box-shadow-red: 0px 23px 40px #E1295B33;
  --box-shadow-green: 0px 23px 40px #2B262633;
  --box-shadow-dark-blue: 0px 23px 40px #30297F33;
  --box-shadow-form: 0px 23px 40px #00000029;
  --box-shadow-literature: 0px 23px 40px #0000001A;
}

:root {
  /*------- Font sizes -------*/
  --font-size-sm: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
  --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);
  --font-size-md: clamp(1.2rem, 0.61vw + 1.1rem, 1.4rem);
  --font-size-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);
  --font-size-xl: clamp(1.95rem, 1.56vw + 1.56rem, 2.81rem);
  --font-size-xxl: clamp(1.8rem, 2.38vw + 1.85rem, 2.5rem);
  --font-size-xxxl: clamp(3.05rem, 3.54vw + 2.17rem, 5rem);
  /*------- Font weights -------*/
  --bold: 600;
  --semibold: 500;
  --regular: 400;
}

@font-face {
  font-family: helvetica-neue;
  src: format("woff"), url(../fonts/HelveticaNeue.woff) format("woff"), url(../fonts/HelveticaNeue.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: helvetica-neue;
  src: url(../fonts/HelveticaNeue-Medium.woff) format("woff"), format("woff"), url(../fonts/HelveticaNeue-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: helvetica-neue;
  src: url(../fonts/helvetica-neue/HelveticaNeue-Bold.woff) format("woff"), format("woff"), url(../fonts/HelveticaNeue-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
/* Montserrat Regular */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.woff) format("woff"), format("woff"), url(../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}
/* Montserrat Medium */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Medium.woff) format("woff"), format("woff"), url(../fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
/* Montserrat Bold */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Bold.woff) format("woff"), format("woff"), url(../fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}
:root {
  --font-body: Montserrat, helvetica-neue, arial, sans-serif;
}

/*--------------------------------------------------------------
# Generic
--------------------------------------------------------------*/
/* Box sizing
--------------------------------------------- */
/* Inherit box-sizing to more easily change it's value on a component level.
@link http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: 100%;
}

body {
  box-sizing: border-box;
}

img {
  width: 100%;
}

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
/* Typography
--------------------------------------------- */
h1, h2 {
  font-weight: var(--bold);
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.4rem;
}

h5 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
}

@media screen and (max-width: 576px) {
  h1 {
    font-size: 1.8rem;
  }
}
/* Elements
--------------------------------------------- */
body {
  font-family: var(--font-body);
  color: --primary-dark-grey;
}

/* Links
--------------------------------------------- */
/* Forms
--------------------------------------------- */
.btn__primary_sm {
  width: 190px;
  height: 57px;
  background-color: var(--primary-rose-red);
  border: 1px solid var(--primary-rose-red);
  color: var(--white);
  transition: 0.2s;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn__primary_sm::after {
  float: right;
  content: "";
  background-image: url(../img/icons/applaus-btn-arrow.svg);
  background-size: cover;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  transition: 0.2s;
  filter: brightness(0) invert(1);
}
.btn__primary_sm:hover {
  background-color: transparent;
  color: var(--primary-rose-red);
}
.btn__primary_sm:hover::after {
  transform: rotate(45deg);
  filter: unset;
}

.btn__secondary_sm {
  width: 190px;
  height: 57px;
  background-color: transparent;
  border: 1px solid var(--primary-rose-red);
  color: var(--primary-rose-red);
  transition: 0.2s;
  text-decoration: none;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn__secondary_sm::after {
  float: right;
  content: "";
  background-image: url(../img/icons/applaus-btn-arrow.svg);
  background-size: cover;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  transition: 0.2s;
}
.btn__secondary_sm:hover {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
.btn__secondary_sm:hover::after {
  transform: rotate(45deg);
  filter: brightness(0) invert(1);
}

.btn__tertiary_sm {
  background-color: transparent;
  border: none;
  color: var(--primary-rose-red);
  font-weight: var(--bold);
  text-decoration: none;
}
.btn__tertiary_sm::after {
  float: right;
  content: "";
  background-image: url(../img/icons/applaus-btn-arrow.svg);
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  transition: 0.2s;
}
.btn__tertiary_sm:hover {
  color: var(--primary-rose-red);
}
.btn__tertiary_sm:hover::after {
  transform: rotate(45deg);
}
@media screen and (max-width: 992px) {
  .btn__tertiary_sm::after {
    width: unset;
  }
}

.btn__primary_lg {
  width: 30rem;
  height: 6rem;
  font-size: var(--font-size-md);
  border: 1px solid var(--primary-rose-red);
  background-color: var(--primary-rose-red);
  box-shadow: 0px 23px 40px #E1295B33;
  color: var(--white);
  transition: 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn__primary_lg:hover {
  border: 1px solid var(--primary-rose-red);
  background-color: transparent;
  color: var(--primary-rose-red);
  box-shadow: revert;
  transition: 0.2s;
  color: var(--primary-rose-red);
}

.btn__secondary_lg {
  width: 30rem;
  height: 6rem;
  font-size: var(--font-size-md);
  background-color: transparent;
  border: 1px solid var(--primary-rose-red);
  color: var(--primary-rose-red);
  transition: 0.2s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn__secondary_lg:hover {
  color: var(--white);
  background-color: var(--primary-rose-red);
}

@media screen and (max-width: 576px) {
  .btn__primary_lg,
.btn__secondary_lg {
    width: 100%;
  }
}
.btn-w-100 .btn__secondary_sm,
.btn-w-100 .btn__primary_sm {
  width: 100%;
}
.btn-w-100 .btn__secondary_sm:hover {
  color: var(--primary-rose-red);
}

/*--------------------------------------------------------------
# Layouts
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
/* Navigation
--------------------------------------------- */
header {
  border-top: 2px solid var(--primary-rose-red);
}
header div.primary-menu-wrapper {
  max-width: 1000px;
}
header div.primary-menu-wrapper .btn__secondary_sm {
  height: 50px;
}
header div.primary-menu-wrapper .btn__secondary_sm:hover {
  color: var(--white);
}
header div.primary-menu-wrapper .icons a {
  text-decoration: none;
}
header ul.primary-menu-links {
  display: flex;
  gap: 1.25rem;
  list-style: none;
}
header ul.primary-menu-links li {
  margin-right: 0;
  font-size: 18px;
}
header ul.primary-menu-links a {
  text-decoration: none;
  color: var(--primary-dark-grey);
  transition: 0.1s;
}
header ul.primary-menu-links a:hover {
  color: var(--primary-rose-red);
  border-bottom: 1px solid var(--primary-rose-red);
  padding-bottom: 0.5rem;
  transition: 0.1s;
}
header ul.primary-menu-links .current-menu-item a {
  color: var(--primary-rose-red);
  border-bottom: 1px solid var(--primary-rose-red);
  padding-bottom: 0.5rem;
}
header svg.logo-desktop {
  cursor: pointer;
  overflow: visible;
}
header svg.logo-desktop > *,
header svg.logo-desktop g {
  transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
}
header svg.logo-desktop g.group-33 {
  transform: translate(78.042px);
}
header svg.logo-desktop g.group-9 {
  transform: translate(26.42px, 45.264px);
}
header svg.logo-desktop g.group-10 {
  transform: translate(0px, 26.256px);
}
header svg.logo-desktop g.group-11 {
  transform: translate(37.489px, -3px);
}
header svg.logo-desktop g.group-12 {
  transform: translate(45.429px, 33.734px);
}
header svg.logo-desktop g.group-13 {
  transform: translate(44.045px, 14.923px);
}
header svg.logo-desktop:hover path.path-11 {
  transform: matrix(0.996, -0.087, 0.087, 0.996, -11.446, -76.266);
}
header svg.logo-desktop:hover g.group-33 {
  transform: translate(78.966px, 5px);
}
header svg.logo-desktop:hover g.group-9 {
  transform: translate(26.42px, 41.657px);
}
header svg.logo-desktop:hover g.group-10 {
  transform: translate(5px, 26.256px);
}
header svg.logo-desktop:hover g.group-11 {
  transform: translate(37.489px, -6.077px);
}
header svg.logo-desktop:hover g.group-12 {
  transform: translate(39.429px, 33.734px);
}
header svg.logo-desktop:hover g.group-13 {
  transform: translate(50.045px, 10.923px);
}
header svg.main-nav-icon {
  fill: var(--primary-dark-grey);
  padding: 2px;
  cursor: pointer;
  overflow: visible;
}
header svg.main-nav-icon path {
  transition: 0.1s;
}
header svg.main-nav-icon:hover {
  fill: var(--primary-rose-red);
}
header svg.main-nav-icon:hover .Path_99 {
  transform: rotate(8deg) translate(1px, -2px);
}
header svg.main-nav-icon:hover .Path_98 {
  transform: rotate(-8deg) translate(-4px, 2px);
}
header img.mobile-logo {
  width: 50%;
}
@media screen and (max-width: 768px) {
  header img.mobile-logo {
    width: 40%;
  }
  header svg.main-nav-icon {
    padding: 4px;
    margin-top: 12px;
  }
}

div.burger-menu-overlay {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--body-overlay);
  visibility: hidden;
  opacity: 0;
  transform: scale(1.09);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  box-sizing: border-box;
}
div.burger-menu-overlay.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
div.burger-menu-overlay img.applaus-logo-white {
  width: 30%;
  margin: 1rem 0 0 1rem;
}
@media screen and (max-width: 768px) {
  div.burger-menu-overlay img.applaus-logo-white {
    width: 20%;
  }
}
@media screen and (max-width: 576px) {
  div.burger-menu-overlay img.applaus-logo-white {
    width: 30%;
  }
}
div.burger-menu-overlay div.burger-menu-wrapper {
  background-color: var(--primary-rose-red);
  height: min-content;
}
div.burger-menu-overlay div.burger-menu-wrapper ul {
  list-style: none;
  padding: 0;
}
div.burger-menu-overlay div.burger-menu-wrapper a {
  color: var(--white);
  text-decoration: none;
}
div.burger-menu-overlay div.burger-menu-wrapper .btn__secondary_sm {
  border: 1px solid var(--white);
  width: auto;
  padding-inline: 20px;
}
div.burger-menu-overlay div.burger-menu-wrapper .btn__secondary_sm::after {
  filter: brightness(0) invert(1);
}
div.burger-menu-overlay div.burger-menu-wrapper div.sidebar-some-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
div.burger-menu-overlay div.menu-primary a {
  font-size: 2rem;
}
div.burger-menu-overlay div.menu-secondary a {
  font-size: 1.5rem;
}
div.burger-menu-overlay div.btn-close-menu svg {
  cursor: pointer;
  transition: 0.2s;
}
div.burger-menu-overlay div.btn-close-menu svg:hover {
  transform: scale(1.1);
}

/* Posts and pages
--------------------------------------------- */
/* Comments
--------------------------------------------- */
/* Widgets
--------------------------------------------- */
/* Media
--------------------------------------------- */
/* Captions
--------------------------------------------- */
/* Galleries
--------------------------------------------- */
footer.site-footer div.subscription-cta-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
footer.site-footer div.subscription-cta-wrapper div.col-left {
  grid-area: 1/1/2/3;
}
footer.site-footer div.subscription-cta-wrapper div.col-right {
  grid-area: 1/2/2/3;
}
footer.site-footer img.image-logo {
  width: 50%;
}
footer.site-footer img.footer-logo-img {
  width: 50%;
}
footer.site-footer img.footer-some__icon {
  width: 14%;
  margin-right: 4px;
}
footer.site-footer .footer-col__ul {
  padding: 0;
}
footer.site-footer .footer-col__li {
  list-style: none;
}
footer.site-footer .footer-col__content p {
  font-size: var(--font-size-md);
}
footer.site-footer a.footer-link {
  color: var(--primary-dark-grey);
  text-decoration: none;
}
footer.site-footer a.footer-link:hover {
  color: var(--primary-rose-red);
}
footer.site-footer a.footer-link, footer.site-footer .footer-col__content p {
  font-size: 1.2rem;
}
footer.site-footer form input {
  border: none;
  border-bottom: 1px solid var(--primary-rose-red);
}
footer.site-footer form input:focus {
  outline: none;
  background-color: var(--primary-light-grey);
}
footer.site-footer form input[type=text] {
  width: 100%;
}
footer.site-footer form input[type=email] {
  width: 100%;
}
footer.site-footer form input[type=submit]:hover {
  border: 1px solid var(--primary-rose-red);
}
footer.site-footer form label {
  color: var(--primary-rose-red);
  display: block;
}
footer.site-footer form label a {
  color: var(--primary-rose-red);
}
footer.site-footer form input[type=checkbox] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--primary-rose-red);
  background: white;
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
footer.site-footer form input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  footer.site-footer form input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  footer.site-footer form input[type=checkbox]:checked {
    background: var(--primary-rose-red);
    width: 1.2rem;
    height: 1.2rem;
  }
  footer.site-footer form input[type=checkbox]::after {
    content: "";
    width: 0.3rem;
    height: 0.7rem;
    border-radius: 0;
    border: 2px solid var(--white);
    border-top: 0;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-0.1rem) rotate(0deg);
    place-self: center;
    opacity: 0;
  }
  footer.site-footer form input[type=checkbox]:checked::after {
    opacity: 1;
    transform: translateY(-0.1rem) rotate(45deg);
  }
}
footer.site-footer .wpcf7 form.sent .wpcf7-response-output {
  border: 1px solid var(--primary-rose-red);
}
@media screen and (min-width: 1600px) {
  footer.site-footer img.subscription-cta__img {
    max-height: 80%;
    object-fit: cover;
  }
}
@media screen and (max-width: 992px) {
  footer.site-footer a.footer-link, footer.site-footer .footer-col__content p {
    font-size: 1.2rem;
  }
  footer.site-footer img.footer-some__icon {
    width: 6%;
  }
  footer.site-footer img.image-logo {
    width: 30%;
  }
  footer.site-footer img.footer-logo-img {
    width: 25%;
  }
}
@media screen and (max-width: 768px) {
  footer.site-footer img.footer-some__icon {
    width: 10%;
  }
}
@media screen and (max-width: 576px) {
  footer.site-footer img.footer-some__icon {
    width: 12%;
  }
  footer.site-footer img.image-logo {
    width: 50%;
  }
}

.subscription-cta__ {
  width: 100%;
}

.wpcf7-list-item {
  margin: 0;
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border: none;
  padding: 0;
  margin: 0;
  color: var(--primary-rose-red);
}

div.post-item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}
@media screen and (max-width: 1200px) {
  div.post-item-grid {
    grid-gap: 10px;
  }
  div.post-item-grid.kursus-archive, div.post-item-grid.netvaerksmode-archive {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
@media screen and (max-width: 992px) {
  div.post-item-grid {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}

div.post-item-card a.post-item-link {
  text-decoration: none;
  color: var(--primary-dark-grey);
}
div.post-item-card a.post-item-link div.text-wrapper .excerpt {
  font-size: var(--font-size-sm);
  min-height: 75px;
}
div.post-item-card img.post-image {
  object-fit: cover;
  height: auto;
  aspect-ratio: 200/107;
}
div.post-item-card .post-item-date {
  opacity: 0.7;
}
div.post-item-card .post-item-header {
  font-size: 1.3rem;
}

@media screen and (max-width: 1200px) {
  a.post-item-link {
    display: grid;
  }
  a.post-item-link img.post-image,
a.post-item-link span.category {
    grid-area: 1/1/2/2;
  }
  a.post-item-link div.text-wrapper {
    min-height: auto;
    z-index: 1;
    grid-area: 2/1/3/2;
  }
}
@media screen and (max-width: 768px) {
  div.post-item-card .post-item-header {
    font-size: 1.2rem;
  }

  .btn__secondary_sm {
    width: 96%;
    margin: auto;
  }

  h1.xl-header {
    font-size: 2.3rem;
  }

  .post-item-date {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 576px) {
  div.post-item-card .post-item-header {
    font-size: 0.8rem;
  }
  div.post-item-card.vaerktoj-card img.post-image {
    height: 90px;
  }

  .netvaerksmode-archive div.post-item-card .post-item-header {
    font-size: 1rem;
  }

  h1.xl-header {
    font-size: 1.5rem;
  }

  .see-more-btn {
    font-size: 1rem;
    width: 180px;
  }
}
section.courses-cta div.cta-item {
  height: 26rem;
}
section.courses-cta div.cta-text {
  height: 6rem;
}
section.courses-cta img.cta-img {
  width: 25%;
}
section.courses-cta div.item-1 {
  background-color: var(--primary-pink);
  color: var(--primary-rose-red);
}
section.courses-cta div.item-2 {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.courses-cta div.item-3 {
  background-color: var(--primary-dark-grey);
  color: var(--white);
}
section.courses-cta .btn__secondary_sm {
  border: 1px solid var(--white);
  color: var(--white);
}
section.courses-cta .btn__secondary_sm:hover {
  background-color: transparent;
}
section.courses-cta .btn__secondary_sm::after {
  filter: brightness(0) invert(1);
}
@media screen and (max-width: 576px) {
  section.courses-cta .btn__primary_sm, section.courses-cta .btn__secondary_sm {
    width: 100%;
  }
  section.courses-cta img.cta-img {
    width: 35%;
  }
}

section.testimonial-slider-section div.testimonial-slider {
  display: grid;
}
section.testimonial-slider-section div.testimonial-item {
  color: var(--white);
}
section.testimonial-slider-section div.testimonial-item > * {
  opacity: 0.9;
}
section.testimonial-slider-section div.testimonial-item .job_title {
  font-size: var(--font-size-sm);
}
section.testimonial-slider-section div.testimonial-item .user-testimonial {
  border-bottom: 1px solid;
  font-style: italic;
}
section.testimonial-slider-section button.slick_btn {
  grid-area: 1/1/2/2;
  margin-top: -105px;
  justify-self: end;
  height: 40px;
  width: 40px;
  background-color: transparent;
  border: 1px solid var(--primary-rose-red);
  color: var(--primary-rose-red);
}
section.testimonial-slider-section button.btn_prev {
  margin-right: 13rem;
}
section.testimonial-slider-section button.btn_next {
  margin-right: 10rem;
}
section.testimonial-slider-section .slick-initialized .slick-slide {
  padding-bottom: 4rem;
}
section.testimonial-slider-section .purple-night {
  box-shadow: var(--box-shadow-purple);
}
section.testimonial-slider-section .rose-red {
  box-shadow: var(--box-shadow-red);
}
section.testimonial-slider-section .dark-grey {
  box-shadow: var(--box-shadow-dark);
}
section.testimonial-slider-section .slick-disabled {
  opacity: 0.3;
}
@media screen and (max-width: 768px) {
  section.testimonial-slider-section button.slick_btn {
    display: none !important;
  }
}

section.frontend-hero {
  overflow: hidden;
}
section.frontend-hero div.frontend-hero-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
section.frontend-hero div.col-left {
  grid-area: 1/1/2/3;
}
section.frontend-hero div.col-right {
  grid-area: 1/2/2/3;
}
section.frontend-hero div.hero-grid {
  display: grid;
}
section.frontend-hero div.hero-grid > * {
  grid-area: 1/1/2/2;
}
section.frontend-hero video {
  height: 60vh;
  width: 100%;
  object-fit: cover;
}
section.frontend-hero a.svg-wrapper {
  align-self: end;
  margin-left: -2rem;
  margin-bottom: -2rem;
  cursor: pointer;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: 1px solid var(--primary-dark-grey);
  padding: 18px;
  background-color: var(--white);
  display: grid;
  justify-items: center;
  align-items: center;
}
section.frontend-hero a.svg-wrapper > * {
  grid-area: 1/1/2/2;
}
section.frontend-hero a.svg-wrapper svg {
  height: auto;
  max-width: 100%;
  width: 8rem;
  transform-origin: center center;
  transform: rotate(30deg);
  overflow: visible;
  letter-spacing: 3px;
  fill: var(--primary-dark-grey);
  animation: spin 40s linear infinite;
}
section.frontend-hero a.svg-wrapper img.arrow {
  width: 44%;
  transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
}
section.frontend-hero a.svg-wrapper:hover img.arrow {
  transform: translate(10px, -10px);
}
@media screen and (max-width: 1200px) {
  section.frontend-hero div.frontend-hero-grid {
    grid-template-columns: auto;
  }
  section.frontend-hero div.col-left {
    grid-area: 2/1/3/2;
  }
  section.frontend-hero div.col-right {
    grid-area: 1/1/2/2;
  }
  section.frontend-hero a.svg-wrapper {
    justify-self: end;
    margin-right: 2rem;
  }
}
@media screen and (max-width: 768px) {
  section.frontend-hero a.svg-wrapper {
    justify-self: end;
    margin-left: 0;
    margin-bottom: 3rem;
  }
  section.frontend-hero video, section.frontend-hero .bg-video {
    clip-path: polygon(0% 0%, 100% 0%, 100% 76%, 0% 100%);
    z-index: -1;
  }
  section.frontend-hero .hero-grid {
    position: relative;
    height: 65vh;
    width: 100%;
  }
  section.frontend-hero .bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 118vh;
  }
}
@media screen and (max-width: 450px) {
  section.frontend-hero a.svg-wrapper {
    margin: 0;
    justify-self: center;
    margin-left: 12rem;
  }
  section.frontend-hero .btn__primary_sm {
    width: 100%;
  }
}

section.course-network-single-page a:not(.btn__primary_sm):not(.btn__primary_lg):not(.btn__secondary_sm):not(.btn__secondary_lg):not(.btn__tertiary_sm):not(.btn__tertiary_lg) {
  color: var(--primary-rose-red);
}
section.course-network-single-page div.post-hero-image-banner {
  display: grid;
  color: var(--white);
  position: relative;
  overflow: hidden;
}
section.course-network-single-page div.post-hero-image-banner > * {
  grid-area: 1/1/2/2;
}
section.course-network-single-page img.post-hero-image {
  height: 74vh;
  object-fit: cover;
  mix-blend-mode: darken;
  max-height: 800px;
}
section.course-network-single-page div.post-hero-text {
  align-self: center;
  z-index: 1;
}
section.course-network-single-page .publikums-title {
  color: var(--primary-dark-grey);
}
section.course-network-single-page div.price-box .price {
  font-weight: var(--bold);
}
section.course-network-single-page div.price-box .price-currency {
  font-weight: 400;
  opacity: 0.8;
  font-size: 1rem;
}
section.course-network-single-page div.pink-box {
  background-color: var(--secondary-pink);
  color: var(--primary-rose-red);
}
section.course-network-single-page div.main-content-wrapper {
  z-index: 1;
}
section.course-network-single-page div.main-content-wrapper img.image-left {
  width: 300px;
  height: 250px;
  object-fit: cover;
}
section.course-network-single-page div.sidebar-wrapper {
  box-shadow: var(--box-shadow-dark);
  height: fit-content;
  border-bottom: 8px solid var(--primary-rose-red);
  top: 5rem;
  background-color: var(--white);
}
section.course-network-single-page div.sidebar-wrapper .tx-red-lg {
  color: var(--primary-rose-red);
  text-transform: uppercase;
  font-size: 50px;
  font-weight: var(--bold);
}
section.course-network-single-page div.sidebar-wrapper p.label {
  font-weight: var(--bold);
}
section.course-network-single-page div.sidebar-wrapper .text-end {
  font-size: 0.8rem;
}
section.course-network-single-page div.sidebar-wrapper .btn__secondary_sm:hover {
  color: var(--white);
}
section.course-network-single-page div.sidebar-wrapper .btn-w-100 .btn__secondary_sm,
section.course-network-single-page div.sidebar-wrapper .btn-w-100 .btn__primary_sm {
  width: 100%;
}
section.course-network-single-page div.sidebar-wrapper .btn-w-100 .btn__secondary_sm svg,
section.course-network-single-page div.sidebar-wrapper .btn-w-100 .btn__primary_sm svg {
  width: 6%;
}
section.course-network-single-page div.menu-options-wrapper {
  border-bottom: 1px solid var(--border-line);
  background-color: var(--white);
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: 11;
}
section.course-network-single-page div.menu-options-wrapper ul {
  background-color: var(--white);
  overflow: auto;
  white-space: nowrap;
  list-style: none;
}
section.course-network-single-page div.menu-options-wrapper li {
  margin-right: 1.8rem;
}
section.course-network-single-page div.menu-options-wrapper a {
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--primary-dark-grey);
}
section.course-network-single-page div.menu-options-wrapper a:hover {
  color: var(--primary-rose-red);
}
section.course-network-single-page div.menu-options-wrapper a:active {
  color: var(--primary-rose-red);
}
section.course-network-single-page div.menu-options-wrapper div.bg-main {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.course-network-single-page .cr-top-right {
  width: 45%;
  padding: 10px;
  position: absolute;
  text-align: center;
  color: var(--white);
  background-color: var(--primary-rose-red);
  top: 18%;
  right: -12%;
  transform: rotate(45deg);
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 1400px) {
  section.course-network-single-page div.sidebar-wrapper .tx-red-lg {
    font-size: 40px;
  }
}
@media screen and (max-width: 1200px) {
  section.course-network-single-page div.sidebar-wrapper .tx-red-lg {
    font-size: 30px;
  }
  section.course-network-single-page .cr-top-right {
    width: 55%;
    top: 15%;
    right: -15%;
    font-size: 16px;
  }
}
@media screen and (max-width: 992px) {
  section.course-network-single-page .btn__tertiary_sm svg {
    width: 4%;
  }
  section.course-network-single-page .cr-top-right {
    width: 80%;
    top: 12%;
    right: -22%;
  }
}
@media screen and (max-width: 768px) {
  section.course-network-single-page img.post-hero-image {
    height: 40vh;
  }
  section.course-network-single-page .cr-top-right {
    width: 72%;
    top: 22%;
    right: -22%;
  }
}
@media screen and (max-width: 576px) {
  section.course-network-single-page div.post-hero-text {
    margin-top: 18rem;
  }
  section.course-network-single-page div.sidebar-wrapper {
    margin: auto;
    border-top: 8px solid var(--primary-rose-red);
  }
  section.course-network-single-page .btn__tertiary_sm svg {
    width: 8%;
  }
  section.course-network-single-page .cr-top-right {
    width: 78%;
    top: 16%;
    right: -22%;
    font-size: 12px;
  }
}

section.custom-network img.post-hero-image {
  height: auto;
}
section.custom-network div.sidebar-wrapper {
  border-top: 8px solid var(--primary-rose-red);
  margin-top: -5.6rem;
  z-index: 22;
  top: 0;
}
@media screen and (max-width: 1200px) {
  section.custom-network div.sidebar-wrapper {
    margin-top: unset;
    z-index: unset;
  }
}
@media screen and (max-width: 992px) {
  section.custom-network .cr-top-right {
    width: 72%;
    top: 24%;
    right: -22%;
  }
}

section.post-archive-block div.section-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: end;
}
section.post-archive-block div.section-header strong {
  color: var(--primary-rose-red);
  text-decoration: underline;
}
section.post-archive-block div.col-left {
  grid-area: 1/1/2/3;
  align-self: center;
}
section.post-archive-block div.col-right {
  grid-area: 1/4/2/5;
}
@media screen and (max-width: 576px) {
  section.post-archive-block div.col-left {
    grid-area: 2/1/2/5;
  }
  section.post-archive-block img.arrow-xxl {
    width: 100%;
  }
}

section.frontpage-post-overview div.report-card {
  position: relative;
}
section.frontpage-post-overview div.report-card span.category {
  border: transparent;
  color: var(--white);
  padding: 4px 10px;
  font-size: 10px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  position: absolute;
  margin-top: -1rem;
  right: 1rem;
  z-index: 99;
  min-width: 160px;
  text-align: center;
}
section.frontpage-post-overview .draggable {
  padding: 0 !important;
  overflow: visible;
}
@media screen and (max-width: 992px) {
  section.frontpage-post-overview {
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  section.frontpage-post-overview div.report-card span.category {
    left: 0;
    right: unset;
  }
}

div.course-card {
  position: relative;
}
div.course-card div.img-wrapper {
  display: grid;
  color: var(--white);
}
div.course-card div.img-wrapper > * {
  grid-area: 1/1/2/2;
}
div.course-card div.img-wrapper img.post-image {
  mix-blend-mode: darken;
}
div.course-card div.img-wrapper h3 {
  align-self: center;
  justify-self: center;
  z-index: 1;
}
div.course-card span.gratis-label {
  color: var(--white);
  z-index: 1;
  background-color: var(--primary-dark-grey);
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2%;
  text-transform: uppercase;
  padding: 4px 10px;
  font-size: 10px;
  letter-spacing: 0.4px;
}
div.course-card div.price {
  background-color: var(--primary-rose-red);
  height: 50px;
  min-width: 50%;
  width: fit-content;
  padding: 0 2.5rem 0 1.5rem;
  clip-path: polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%);
  color: var(--white);
}
div.course-card div.price-white {
  background-color: var(--white);
  height: 52px;
  margin-bottom: -2px;
  width: 57%;
  clip-path: polygon(0% 0%, 88% 0%, 100% 100%, 0% 100%);
}
div.course-card span {
  font-size: 12px;
  text-transform: uppercase;
}
div.course-card div.text-wrapper {
  box-shadow: var(--box-shadow-dark);
  background-color: var(--white);
}
div.course-card div.text-wrapper-bottom ul {
  list-style: none;
  padding-inline: 20px;
}
div.course-card div.text-wrapper-bottom ul li.li-course:before {
  float: left;
  content: "";
  background-image: url(../img/icons/checkmark.svg);
  background-size: cover;
  position: relative;
  width: 16px;
  height: 12px;
  margin-left: -1.5rem;
  margin-top: 4px;
}
div.course-card div.text-wrapper-bottom img.location-icon {
  width: 5%;
  margin-right: 0.5rem;
}
div.course-card .post-item-header__course {
  font-size: 1.5rem;
}
@media screen and (max-width: 576px) {
  div.course-card span.gratis-label {
    font-size: 10px;
  }
  div.course-card .post-item-header__course {
    font-size: 1.3rem;
  }
}

section.report-news-overview-block button.category-item {
  border: transparent;
  color: var(--white);
  padding: 4px 10px;
  font-size: 10px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  margin-bottom: 2px;
  min-width: 160px;
  text-align: center;
}
section.report-news-overview-block button.all {
  color: var(--primary-dark-grey);
  border: 1px solid var(--primary-dark-grey);
  background-color: transparent;
  box-shadow: var(--box-shadow-dark);
}
section.report-news-overview-block button.purple-night {
  box-shadow: var(--box-shadow-purple);
  border: 1px solid var(--primary-purple-night);
}
section.report-news-overview-block button.purple-night__active {
  background-color: transparent;
  color: var(--primary-purple-night);
}
section.report-news-overview-block button.blue-green {
  box-shadow: var(--box-shadow-green);
  border: 1px solid var(--primary-blue-green);
}
section.report-news-overview-block button.blue-green__active {
  background-color: transparent;
  color: var(--primary-blue-green);
}
section.report-news-overview-block button.rose-red {
  box-shadow: var(--box-shadow-red);
  border: 1px solid var(--primary-rose-red);
}
section.report-news-overview-block button.rose-red__active {
  background-color: transparent;
  color: var(--primary-rose-red);
}
section.report-news-overview-block button.dark-grey {
  box-shadow: var(--box-shadow-dark);
  border: 1px solid var(--primary-dark-grey);
}
section.report-news-overview-block button.dark-grey__active {
  background-color: transparent;
  color: var(--primary-dark-grey);
}
section.report-news-overview-block button.dark-blue {
  box-shadow: var(--box-shadow-dark-blue);
  border: 1px solid var(--secondary-dark-blue);
}
section.report-news-overview-block button.dark-blue__active {
  background-color: transparent;
  color: var(--secondary-dark-blue);
}
section.report-news-overview-block div.loader-overlay {
  height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* Safari */
}
section.report-news-overview-block div.loader-overlay div.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #fffbfbeb;
  width: 4rem;
  height: 4rem;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin-top: 6rem;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
section.report-news-overview-block div.loader-overlay.hide-loader {
  display: none;
}
section.report-news-overview-block div.filter-menu-mobile {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-rose-red);
  color: var(--white);
  visibility: hidden;
  opacity: 0;
  transform: scale(1.09);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  box-sizing: border-box;
}
section.report-news-overview-block div.filter-menu-mobile.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
section.report-news-overview-block form input[type=checkbox] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--white);
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
section.report-news-overview-block form input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  section.report-news-overview-block form input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  section.report-news-overview-block form input[type=checkbox]:checked {
    background: var(--white);
    width: 1.2rem;
    height: 1.2rem;
  }
  section.report-news-overview-block form input[type=checkbox]::after {
    content: "";
    width: 0.3rem;
    height: 0.7rem;
    border-radius: 0;
    border: 2px solid var(--primary-rose-red);
    border-top: 0;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-0.1rem) rotate(0deg);
    place-self: center;
    opacity: 0;
  }
  section.report-news-overview-block form input[type=checkbox]:checked::after {
    opacity: 1;
    transform: translateY(-0.1rem) rotate(45deg);
  }
}
section.report-news-overview-block .hide-btn {
  visibility: hidden;
}

span.category {
  border: transparent;
  color: var(--white);
  padding: 4px 10px;
  font-size: 10px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  position: absolute;
  margin-top: -1rem;
  right: 1rem;
  z-index: 99;
  min-width: 160px;
  text-align: center;
  font-weight: var(--bold);
}

@media screen and (max-width: 992px) {
  span.category {
    right: unset;
    left: 0;
    font-weight: var(--normal);
  }
}
@media screen and (max-width: 576px) {
  span.category {
    font-size: 9px;
  }
}
section.not-found-section div.not-found-wrapper {
  display: grid;
}
section.not-found-section div.not-found-wrapper > * {
  grid-area: 1/1/2/2;
}
section.not-found-section img.image {
  height: 80vh;
  object-fit: cover;
}
section.not-found-section div.not-found-message {
  align-self: center;
  justify-self: center;
  color: var(--white);
  text-align: center;
}
section.not-found-section h1.heading {
  font-size: 100px;
}
section.not-found-section h3.text {
  font-size: 24px;
}
section.not-found-section .btn__primary_lg {
  border: 1px solid var(--white);
  background-color: transparent;
  box-shadow: unset;
}
section.not-found-section .btn__primary_lg:hover {
  color: var(--white);
}
@media screen and (max-width: 992px) {
  section.not-found-section h1.heading {
    font-size: 70px;
  }
}

section.vaerktoj-subcategory .rose-red,
section.vaerktoj-subcategory .green-sheen,
section.vaerktoj-subcategory .dark-grey {
  color: var(--white);
}
section.vaerktoj-subcategory .pink {
  color: var(--primary-rose-red);
}

section.kursus-archive a.post-item-link > *,
section.netvaerksmode-archive a.post-item-link > * {
  grid-area: unset;
}
section.kursus-archive div.text-wrapper,
section.netvaerksmode-archive div.text-wrapper {
  color: var(--primary-dark-grey);
}
section.kursus-archive div.post-item-card .post-item-header,
section.netvaerksmode-archive div.post-item-card .post-item-header {
  min-height: 75px;
}
@media screen and (max-width: 1200px) {
  section.kursus-archive div.post-item-grid,
section.netvaerksmode-archive div.post-item-grid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
@media screen and (max-width: 576px) {
  section.kursus-archive div.post-item-grid,
section.netvaerksmode-archive div.post-item-grid {
    width: 100%;
    grid-template-columns: auto;
  }
  section.kursus-archive div.post-item-card .post-item-header,
section.netvaerksmode-archive div.post-item-card .post-item-header {
    min-height: auto;
  }
}

.related-posts-block.frontpage div.post-header .tx-red {
  text-transform: uppercase;
  font-size: var(--font-size-xxxl);
}
@media screen and (max-width: 1200px) {
  .related-posts-block .kursus-archive div.post-item-card:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .related-posts-block .btn__tertiary_sm svg {
    width: 5%;
  }
  .related-posts-block .kursus-archive div.post-item-card:nth-child(3) {
    display: none;
  }
  .related-posts-block div.post-item-card:nth-child(3) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .related-posts-block div.post-item-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .related-posts-block div.post-item-card {
    display: block;
  }
}
@media screen and (max-width: 576px) {
  .related-posts-block div.post-item-grid {
    grid-template-columns: auto;
  }
  .related-posts-block div.post-item-card img.post-image {
    height: 200px;
  }
  .related-posts-block div.post-item-card .post-item-header {
    font-size: 1.2rem;
  }
  .related-posts-block .kursus-archive div.post-item-card:nth-child(2) {
    display: none;
  }
  .related-posts-block div.post-item-card:nth-child(2) {
    display: none;
  }
  .related-posts-block .link-text {
    font-size: 1.1rem;
  }
}

section.post-single-page img.post-hero-image {
  object-fit: cover;
}
section.post-single-page span.category {
  position: unset;
}
section.post-single-page div.summary p {
  font-size: 1.2rem;
}
section.post-single-page a:not(.btn__primary_sm):not(.btn__primary_lg):not(.btn__secondary_sm):not(.btn__secondary_lg):not(.btn__tertiary_sm):not(.btn__tertiary_lg) {
  color: var(--primary-rose-red);
}
section.post-single-page div.sidebar-wrapper {
  box-shadow: var(--box-shadow-dark);
}
section.post-single-page div.sidebar-wrapper .tx-red-lg {
  color: var(--primary-rose-red);
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: var(--bold);
}
section.post-single-page div.sidebar-wrapper .tx-red {
  color: var(--primary-rose-red);
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: var(--bold);
}
section.post-single-page div.sidebar-wrapper .btn-w-100 .btn__secondary_sm,
section.post-single-page div.sidebar-wrapper .btn-w-100 .btn__primary_sm {
  width: 100%;
}
section.post-single-page div.sidebar-wrapper .btn-w-100 .btn__secondary_sm svg,
section.post-single-page div.sidebar-wrapper .btn-w-100 .btn__primary_sm svg {
  width: 6%;
}
section.post-single-page div.sidebar-wrapper .btn-w-100 .btn__secondary_sm:hover {
  color: var(--white);
}
section.post-single-page div.sidebar-wrapper .sidebar-img {
  max-width: 100%;
  display: block;
  margin: auto;
  max-height: 300px;
  width: auto;
}
section.post-single-page .sidebar-header {
  background-color: var(--primary-dark-grey);
  color: var(--white);
}
section.post-single-page .wp-block-image {
  margin-block: 2rem;
}
@media screen and (max-width: 1400px) {
  section.post-single-page div.sidebar-wrapper .tx-red-lg {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1200px) {
  section.post-single-page div.sidebar-wrapper .tx-red-lg {
    font-size: 2rem;
  }
}
@media screen and (max-width: 768px) {
  section.post-single-page img.post-hero-image {
    height: 40vh;
  }
  section.post-single-page div.sidebar-wrapper .sidebar-img {
    max-height: 200px;
  }
}
@media screen and (max-width: 576px) {
  section.post-single-page div.sidebar-wrapper .tx-red {
    font-size: 1.6rem;
  }
  section.post-single-page div.sidebar-container {
    padding: 0;
  }
}

section.contact-us-section div.form-wrapper {
  box-shadow: var(--box-shadow-form);
  margin-top: -6rem;
  background-color: var(--white);
  position: relative;
}
section.contact-us-section div.acf-map {
  width: 100%;
  height: 42rem;
  border: #ccc solid 1px;
  margin: 20px 0;
}
section.contact-us-section div.acf-map img {
  max-width: inherit !important;
}
section.contact-us-section div.info-box-wrapper {
  position: absolute;
  top: 16rem;
  left: 6rem;
  width: 24rem;
  background-color: var(--white);
}
section.contact-us-section div.info-box {
  background-color: var(--white);
}
section.contact-us-section div.info-box .info-box-header {
  color: var(--primary-rose-red);
  font-weight: var(--bold);
}
section.contact-us-section div.info-box .info-box-label {
  font-weight: var(--bold);
}
section.contact-us-section div.info-box .info-box-text {
  font-size: 0.9rem;
}
section.contact-us-section .btn__primary_sm svg {
  width: 8%;
  margin-left: 10px;
}
section.contact-us-section .btn__primary_sm:hover svg {
  transform: unset;
}
section.contact-us-section .btn__primary_sm::after {
  width: unset;
}
section.contact-us-section form input {
  border: none;
  border-bottom: 1px solid var(--primary-rose-red);
  color: var(--primary-dark-grey);
}
section.contact-us-section form input:focus {
  outline: none;
  background-color: var(--primary-light-grey);
}
section.contact-us-section form label {
  color: var(--primary-rose-red);
  width: 100%;
}
section.contact-us-section form label a {
  color: var(--primary-rose-red);
}
section.contact-us-section form textarea {
  width: 100%;
  outline: none;
  border: 1px solid var(--primary-rose-red);
  color: var(--primary-dark-grey);
}
section.contact-us-section form div.input-w-100 input {
  width: 100%;
}
section.contact-us-section form .btn__primary_lg-wrapper input {
  color: var(--white);
}
section.contact-us-section form .btn__primary_lg-wrapper input:hover {
  color: var(--primary-rose-red);
}
section.contact-us-section form .wpcf7-spinner {
  display: none;
}
section.contact-us-section form input[type=checkbox] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--primary-rose-red);
  background: white;
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
section.contact-us-section form input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  section.contact-us-section form input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  section.contact-us-section form input[type=checkbox]:checked {
    background: var(--primary-rose-red);
    width: 1.2rem;
    height: 1.2rem;
  }
  section.contact-us-section form input[type=checkbox]::after {
    content: "";
    width: 0.3rem;
    height: 0.7rem;
    border-radius: 0;
    border: 2px solid var(--white);
    border-top: 0;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-0.1rem) rotate(0deg);
    place-self: center;
    opacity: 0;
  }
  section.contact-us-section form input[type=checkbox]:checked::after {
    opacity: 1;
    transform: translateY(-0.1rem) rotate(45deg);
  }
}
section.contact-us-section .wpcf7 form.sent .wpcf7-response-output {
  border: 1px solid var(--primary-rose-red);
}
@media screen and (max-width: 768px) {
  section.contact-us-section div.info-box-wrapper {
    left: 2rem;
  }
}
@media screen and (max-width: 576px) {
  section.contact-us-section div.acf-map {
    height: 26rem;
  }
  section.contact-us-section div.info-box-wrapper {
    top: 0;
    left: 0;
    margin-top: -90px;
    position: relative;
    max-width: 95%;
    box-shadow: var(--box-shadow-form);
  }
  section.contact-us-section div.form-wrapper {
    margin-top: 24rem;
    background-color: var(--white);
  }
}

section.meet-applaus div.team-member-card {
  position: relative;
}
section.meet-applaus div.team-member-card:hover .overlay {
  height: 52%;
}
section.meet-applaus img.image {
  object-fit: cover;
  object-position: center;
}
section.meet-applaus div.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary-dark-grey);
  overflow: hidden;
  width: 100%;
  height: 5rem;
  transition: 0.3s ease;
}
section.meet-applaus div.overlay .btn__primary_sm:hover {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}
section.meet-applaus div.overlay .btn__primary_sm:hover::after {
  filter: brightness(0) invert(1);
}
section.meet-applaus div.content {
  position: absolute;
  width: 100%;
  top: 46%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -60%);
  text-align: center;
  color: var(--white);
}
section.meet-applaus div.content-header {
  text-transform: uppercase;
  min-height: 80px;
}
section.meet-applaus div.content-header .job-title {
  opacity: 0.8;
}
section.meet-applaus div.content-header .name {
  font-size: 1.2rem;
}
section.meet-applaus div.cta-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
section.meet-applaus .contact-info a {
  text-decoration: none;
  color: var(--white);
}
section.meet-applaus .contact-info a:hover {
  color: var(--white);
}
section.meet-applaus .contact-info.tel a:before,
section.meet-applaus .contact-info.email a:before {
  float: left;
  content: "";
  background-size: cover;
  position: relative;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
}
section.meet-applaus .contact-info.tel a:before {
  background-image: url(../img/icons/circle-phone-flip.svg);
  width: 16px;
  filter: brightness(0) invert(1);
}
section.meet-applaus .contact-info.email a:before {
  background-image: url(../img/icons/envelope.svg);
  width: 17.5px;
  filter: brightness(0) invert(1);
}
@media screen and (max-width: 1400px) {
  section.meet-applaus div.team-member-card {
    position: relative;
  }
  section.meet-applaus div.team-member-card:hover .overlay {
    height: 80%;
  }
}
@media screen and (max-width: 1200px) {
  section.meet-applaus div.team-member-card {
    position: relative;
  }
  section.meet-applaus div.team-member-card:hover .overlay {
    height: 100%;
  }
  section.meet-applaus div.content svg {
    margin-right: 8px;
  }
  section.meet-applaus div.content-header .name, section.meet-applaus div.content-header .job-title {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 576px) {
  section.meet-applaus div.team-member-card {
    position: relative;
  }
  section.meet-applaus div.content-header .name {
    font-size: 0.7rem;
  }
  section.meet-applaus div.content-header .job-title {
    font-size: 0.5rem;
  }
  section.meet-applaus .btn__primary_sm {
    width: 150px;
    height: 50px;
  }
}

div.modal.custom {
  backdrop-filter: blur(9px);
  background-color: var(--body-overlay);
}
div.modal.custom div.modal-dialog-wrapper {
  height: 80%;
}
div.modal.custom div.modal-dialog {
  min-width: 70%;
  margin: 4% auto;
  min-height: 60%;
  max-height: 82%;
}
div.modal.custom div.modal-content {
  border-radius: 0;
  border: none;
}
div.modal.custom img.team-member-image {
  height: 100%;
  object-fit: cover;
}
div.modal.custom .contact-info.tel a,
div.modal.custom .contact-info.email a {
  width: auto;
  padding-inline: 20px;
}
div.modal.custom .contact-info.tel a::before,
div.modal.custom .contact-info.email a::before {
  float: left;
  content: "";
  background-size: cover;
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  filter: brightness(0) invert(1);
}
div.modal.custom .contact-info.tel a:hover::before,
div.modal.custom .contact-info.email a:hover::before {
  filter: unset;
}
div.modal.custom .contact-info.tel a:before {
  background-image: url(../img/icons/circle-phone-flip.svg);
  width: 16px;
}
div.modal.custom .contact-info.email a:before {
  background-image: url(../img/icons/envelope.svg);
  width: 17.5px;
}
div.modal.custom .contact-info.tel a:after,
div.modal.custom .contact-info.email a:after {
  width: unset;
}
@media screen and (max-width: 992px) {
  div.modal.custom div.modal-dialog-wrapper {
    height: 100%;
  }
  div.modal.custom div.modal-dialog {
    margin: 0;
    min-width: 100%;
    min-height: 100%;
  }
  div.modal.custom div.modal-content {
    overflow: visible;
    min-height: 100%;
  }
  div.modal.custom img.team-member-image {
    width: 30%;
    padding-bottom: 1rem;
  }
}
@media screen and (max-width: 576px) {
  div.modal.custom img.team-member-image {
    width: 60%;
  }
}

section.about-text-and-image div.about-grid-image-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
section.about-text-and-image div.col-left {
  grid-area: 1/1/2/3;
}
section.about-text-and-image div.col-right {
  grid-area: 1/2/2/3;
}
section.about-text-and-image .title {
  font-size: 60px;
}
section.about-text-and-image .subtitle {
  font-weight: var(--bold);
}
section.about-text-and-image div.description p {
  color: var(--primary-dark-grey);
}
section.about-text-and-image img.about-img {
  object-fit: cover;
}
section.about-text-and-image div.overlay {
  background-color: var(--body-overlay);
  height: 40vh;
}
@media screen and (max-width: 992px) {
  section.about-text-and-image div.about-grid-image-right {
    grid-template-columns: auto;
  }
  section.about-text-and-image div.col-left {
    grid-area: 1/1/2/2;
  }
  section.about-text-and-image div.col-right {
    grid-area: 2/1/3/2;
    display: grid;
    align-items: stretch;
    color: var(--white);
  }
  section.about-text-and-image div.col-right > * {
    grid-area: 1/1/2/2;
  }
  section.about-text-and-image img.about-img {
    height: 60vh;
  }
}
@media screen and (max-width: 768px) {
  section.about-text-and-image img.about-img {
    height: 40vh;
  }
}
@media screen and (max-width: 450px) {
  section.about-text-and-image div.col-right {
    padding: 0;
  }
}

section.about-accordion strong {
  color: var(--primary-rose-red);
}
section.about-accordion .accordion-item {
  background-color: #fff;
  border: 1px solid var(--primary-rose-red);
  border-bottom: transparent;
}
section.about-accordion .accordion-item:last-child {
  border-bottom: 1px solid var(--primary-rose-red);
}
section.about-accordion .accordion-item:first-of-type {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
section.about-accordion .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
section.about-accordion .accordion-item .btn__primary_sm,
section.about-accordion .accordion-item .btn__secondary_sm {
  width: auto;
  padding-inline: 20px;
}
section.about-accordion .accordion-item a, section.about-accordion .accordion-item a span {
  color: var(--primary-rose-red);
  text-decoration: underline;
}
section.about-accordion .accordion-button {
  color: var(--primary-rose-red);
  font-weight: var(--bold);
  background-color: var(--secondary-pink);
  box-shadow: unset;
}
section.about-accordion .accordion-button::after {
  height: 2rem;
  outline: none;
  box-shadow: none;
  background-image: url(../img/icons/applaus-arrow.svg);
}
section.about-accordion .accordion-button:hover {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.about-accordion .accordion-button:hover::after {
  filter: brightness(0) invert(1);
}
section.about-accordion .accordion-button.collapsed::after {
  transform: rotate(0deg);
}
section.about-accordion .accordion-button:not(.collapsed) {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.about-accordion .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
  filter: brightness(0) invert(1);
}
section.about-accordion button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: none;
}
section.about-accordion .accordion-collapse {
  background-color: var(--secondary-pink);
}

section.about-text-and-cta .btn__secondary_sm:hover {
  color: var(--white);
}

section.literature-overview-block div.grid-literature {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}
section.literature-overview-block div.literature-post {
  box-shadow: var(--box-shadow-literature);
  border-left: 6px solid var(--primary-rose-red);
}
section.literature-overview-block div.literature-post a.post-item-link {
  text-decoration: none;
  color: var(--primary-dark-grey);
}
section.literature-overview-block div.literature-post .post-item-header {
  font-size: 1.1rem;
  color: var(--primary-rose-red);
  font-weight: var(--bold);
  min-height: 50px;
}
section.literature-overview-block div.literature-post .card-category {
  color: var(--grey-text);
  font-size: 1rem;
}
section.literature-overview-block div.literature-post ul.card-subcategory {
  color: var(--grey-text-light);
}
section.literature-overview-block div.literature-post ul.card-subcategory li {
  list-style: none;
}
section.literature-overview-block div.literature-post ul.card-subcategory li:after {
  content: ",";
  margin-left: -4px;
  margin-right: 4px;
}
section.literature-overview-block div.literature-post ul.card-subcategory li:last-child::after {
  content: "";
}
section.literature-overview-block div.literature-post .btn__secondary_sm:hover {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.literature-overview-block div.loader-overlay {
  height: 50vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* Safari */
}
section.literature-overview-block div.loader-overlay div.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #fffbfbeb;
  width: 4rem;
  height: 4rem;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin-top: 6rem;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
section.literature-overview-block div.loader-overlay.hide-loader {
  display: none;
}
section.literature-overview-block div.not-found-message .category-item-all {
  color: var(--primary-rose-red);
  padding: 0.5rem 0;
}
section.literature-overview-block div.search-group input {
  border: none;
  outline: none;
  border-bottom: 1px solid var(--primary-rose-red);
}
section.literature-overview-block div.search-group input:focus {
  outline: none;
}
section.literature-overview-block div.search-group label {
  color: var(--primary-rose-red);
}
section.literature-overview-block div.search-group .btn__secondary_sm {
  height: fit-content;
  padding: 8px 0;
  outline: none;
}
section.literature-overview-block div.search-group .btn__secondary_sm:hover {
  color: var(--white);
  background-color: var(--primary-rose-red);
}
section.literature-overview-block div.filter-header-wrapper {
  background-color: var(--primary-rose-red);
  color: var(--white);
}
section.literature-overview-block div.filter-header-wrapper .filter-header {
  font-size: 1rem;
}
section.literature-overview-block div.filter-header-wrapper img {
  width: 4%;
  filter: brightness(0) invert(1);
}
section.literature-overview-block button.btn-toggle-filter-mobile {
  background-color: var(--primary-rose-red);
  color: var(--white);
  border: none;
  outline: none;
  height: 2.7rem;
  width: 6rem;
}
section.literature-overview-block button.btn-toggle-filter-mobile img {
  width: 20%;
  margin-left: 6px;
  filter: brightness(0) invert(1);
}
section.literature-overview-block .accordion-item {
  border: unset;
  border-top: 1px solid var(--border-line);
}
section.literature-overview-block .accordion-item:first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
section.literature-overview-block .accordion-item:last-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
section.literature-overview-block .accordion-body label {
  font-size: 0.9rem;
}
section.literature-overview-block .accordion-button {
  color: var(--primary-dark-grey);
  background-color: transparent;
  box-shadow: none;
}
section.literature-overview-block .accordion-button::after {
  background-image: url(../img/icons/angle-small-down-dark.svg);
  background-size: 1rem;
  width: 1rem;
  height: 1rem;
}
section.literature-overview-block .category-item-all {
  color: var(--primary-rose-red);
  background-color: transparent;
  font-weight: var(--bold);
  border: none;
  outline: none;
  padding: 0.5rem 0;
}
section.literature-overview-block .category-item-all:hover {
  color: var(--primary-dark-grey);
}
section.literature-overview-block input[type=checkbox] {
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--primary-dark-grey);
  background: white;
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
section.literature-overview-block input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  section.literature-overview-block input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  section.literature-overview-block input[type=checkbox]:checked {
    border: 4px solid var(--primary-rose-red);
  }
}
section.literature-overview-block div.literature-filter-menu-mobile {
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 110;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-rose-red);
  color: var(--white);
  visibility: hidden;
  opacity: 0;
  transform: scale(1.09);
  transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
  box-sizing: border-box;
}
section.literature-overview-block div.literature-filter-menu-mobile.active {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
@media screen and (max-width: 1200px) {
  section.literature-overview-block div.grid-literature {
    grid-template-columns: auto;
  }
  section.literature-overview-block div.literature-post .post-item-header {
    min-height: auto;
  }
}
@media screen and (max-width: 767px) {
  section.literature-overview-block div.sidebar-filter-wrapper {
    margin-bottom: 10rem;
  }
  section.literature-overview-block .accordion-item {
    background-color: var(--primary-rose-red);
    border-top: 1px solid var(--white);
  }
  section.literature-overview-block .accordion-button {
    color: var(--white);
  }
  section.literature-overview-block .accordion-button::after {
    filter: brightness(0) invert(1);
  }
  section.literature-overview-block .category-item-all {
    color: var(--white);
    border: 1px solid var(--white);
    padding: 0.5rem;
  }
  section.literature-overview-block input[type=checkbox] {
    background: transparent;
    border: 1px solid var(--white);
  }
  @supports (-webkit-appearance: none) or (appearance: none) {
    section.literature-overview-block input[type=checkbox]:checked {
      border: 4px solid var(--white);
    }
  }
}

div.sidebar-details {
  box-shadow: var(--box-shadow-literature);
  border-top: 8px solid var(--primary-rose-red);
  border-bottom: 8px solid var(--primary-rose-red);
}
div.sidebar-details .card-category {
  font-size: 1rem;
}
div.sidebar-details ul.card-subcategory {
  color: var(--grey-text-light);
}
div.sidebar-details ul.card-subcategory li {
  list-style: none;
}
div.sidebar-details ul.card-subcategory li:after {
  content: ",";
  margin-left: -4px;
  margin-right: 4px;
}
div.sidebar-details ul.card-subcategory li:last-child::after {
  content: "";
}

div.newsletter-popup-form {
  width: 200px;
  height: 3.5rem;
  position: fixed;
  right: 4%;
  bottom: 0;
  z-index: 99;
  transition: 0.4s;
  background-color: var(--primary-purple-night);
}
div.newsletter-popup-form.active {
  height: auto;
  width: fit-content;
  transition: 0.4s;
}
div.newsletter-popup-form.active .active-none {
  display: block;
}
div.newsletter-popup-form.active .popup-title {
  font-size: 1.4rem;
  margin-top: 1rem;
  margin-left: 0;
}
div.newsletter-popup-form form {
  background-color: var(--primary-purple-night);
  color: var(--white);
  padding: 1.4rem;
}
div.newsletter-popup-form form input[type=text],
div.newsletter-popup-form form input[type=email] {
  background-color: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #ffffffb0;
  color: var(--white);
}
div.newsletter-popup-form form input[type=checkbox] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--white);
  background: transparent;
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
div.newsletter-popup-form form input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  div.newsletter-popup-form form input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  div.newsletter-popup-form form input[type=checkbox]:checked {
    background: var(--white);
    width: 1.2rem;
    height: 1.2rem;
  }
  div.newsletter-popup-form form input[type=checkbox]::after {
    content: "";
    width: 0.3rem;
    height: 0.7rem;
    border-radius: 0;
    border: 2px solid var(--primary-purple-night);
    border-top: 0;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-0.1rem) rotate(0deg);
    place-self: center;
    opacity: 0;
  }
  div.newsletter-popup-form form input[type=checkbox]:checked::after {
    opacity: 1;
    transform: translateY(-0.1rem) rotate(45deg);
  }
}
div.newsletter-popup-form p {
  margin-bottom: 0;
}
div.newsletter-popup-form .wpcf7-response-output {
  border: 2px solid var(--white);
  width: 300px;
  margin: 0;
  border: none;
  padding-left: 0;
}
div.newsletter-popup-form .wpcf7-not-valid-tip {
  color: var(--white);
}
div.newsletter-popup-form .popup-close-btn {
  cursor: pointer;
  display: block;
}
div.newsletter-popup-form .popup-close-btn:after {
  content: "";
  background-image: url(../img/icons/popup-close-btn.svg);
  width: 26px;
  height: 26px;
  margin-right: -5px;
  margin-top: -5px;
  position: absolute;
  top: 0;
  right: 0;
}
div.newsletter-popup-form .popup-title {
  cursor: pointer;
  font-size: 1rem;
  margin-top: -0.6rem;
  margin-left: 1rem;
  text-align: center;
}
div.newsletter-popup-form .popup-title:after {
  content: "";
  background-image: url(../img/icons/paper-plane.svg);
  background-size: cover;
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 0.5rem;
  margin-top: 4px;
  display: inline-block;
}
div.newsletter-popup-form span.wpcf7-list-item-label a {
  color: var(--white);
}
div.newsletter-popup-form .active-none {
  display: none;
}
div.newsletter-popup-form .btn__secondary_sm {
  color: var(--white);
  border: 1px solid var(--white);
}
div.newsletter-popup-form .btn__secondary_sm:hover {
  background-color: transparent;
}
@media screen and (max-width: 768px) {
  div.newsletter-popup-form {
    display: none;
  }
  div.newsletter-popup-form.active {
    height: 100%;
    width: 100%;
    right: 0;
    background-color: var(--primary-purple-night);
    display: grid;
    grid-template-rows: 10% 90%;
    align-items: center;
    justify-content: center;
  }
  div.newsletter-popup-form .popup-close-btn {
    display: none;
  }
}
div.newsletter-popup-form .wpcf7 form.invalid .wpcf7-response-output,
div.newsletter-popup-form .wpcf7 form.unaccepted .wpcf7-response-output,
div.newsletter-popup-form .wpcf7 form.payment-required .wpcf7-response-output {
  color: var(--white);
}

div.popup-open-btn-mobile {
  position: fixed;
  bottom: 1%;
  right: 1%;
  background-color: var(--primary-purple-night);
  padding: 18px;
  border-radius: 50px;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  cursor: pointer;
}

div.popup-close-btn-mobile {
  width: 16%;
  justify-self: end;
}

@media screen and (max-width: 768px) {
  div.popup-close-btn-mobile {
    margin-top: 16rem;
  }
}
@media screen and (max-width: 576px) {
  div.popup-close-btn-mobile {
    margin-top: 10rem;
  }
}
section.buy-subscription div.subscription-cta-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}
section.buy-subscription div.subscription-cta-wrapper > * {
  grid-area: 1/1/2/2;
}
section.buy-subscription div.col-right {
  z-index: 11;
}
section.buy-subscription div.col-right a {
  color: var(--primary-rose-red);
}
section.buy-subscription div.col-right a:hover {
  color: var(--primary-dark-grey);
}
@media screen and (min-width: 1600px) {
  section.buy-subscription img.subscription-cta__img {
    max-height: 80%;
    object-fit: cover;
  }
}
@media screen and (min-width: 1200px) {
  section.buy-subscription div.col-left {
    position: sticky;
    height: fit-content;
    top: 5rem;
  }
  section.buy-subscription div.col-right {
    justify-self: end;
  }
}
@media screen and (max-width: 1200px) {
  section.buy-subscription div.col-right {
    grid-area: 2/1/3/2;
  }
}

div.post-item-card-rn {
  position: relative;
}
div.post-item-card-rn a.post-item-link {
  text-decoration: none;
  color: var(--primary-dark-grey);
}
div.post-item-card-rn a.post-item-link div.text-wrapper .excerpt {
  font-size: var(--font-size-sm);
}
div.post-item-card-rn img.post-image {
  object-fit: cover;
  height: auto;
  aspect-ratio: 200/107;
}
div.post-item-card-rn .post-item-date {
  opacity: 0.7;
}
div.post-item-card-rn .post-item-header {
  font-size: 1.3rem;
  min-height: 75px;
}
div.post-item-card-rn .card-badge {
  position: absolute;
  right: 1rem;
  min-width: 200px;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--white);
  text-align: center;
  padding: 6px;
  font-weight: var(--bold);
}

@media screen and (max-width: 768px) {
  div.post-item-card-rn .post-item-header {
    font-size: 1.2rem;
    min-height: auto;
  }
}
@media screen and (max-width: 576px) {
  div.post-item-card-rn .post-item-header {
    font-size: 0.8rem;
  }
  div.post-item-card-rn.vaerktoj-card img.post-image {
    height: 90px;
  }
}
div.grid-image-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
div.grid-image-right .col-left {
  grid-area: 1/1/2/3;
}
div.grid-image-right .col-right {
  grid-area: 1/2/2/3;
}
div.grid-image-right .description p {
  color: var(--primary-dark-grey);
}
@media screen and (max-width: 992px) {
  div.grid-image-right {
    grid-template-columns: auto;
  }
  div.grid-image-right .col-left {
    grid-area: unset;
  }
  div.grid-image-right .col-right {
    grid-area: 1/1/2/2;
  }
}

div#download-report div.modal-content {
  padding: 2rem;
  margin-top: 10rem;
  background-color: var(--primary-rose-red);
}
div#download-report button.download-report-close {
  background-image: url(../img/icons/modal-close-btn.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  top: 25px;
  right: 25px;
  position: absolute;
  background-size: cover;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  transition: 0.1s;
}
div#download-report button.download-report-close:hover {
  transform: scale(1.1);
}
div#download-report a {
  color: var(--white);
}
div#download-report form {
  color: var(--white);
}
div#download-report div.modal-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
div#download-report label {
  width: 100%;
}
div#download-report input[type=text],
div#download-report input[type=email] {
  background-color: transparent !important;
  background: var(--primary-rose-red);
  border: none;
  border-bottom: 1px solid var(--white);
  color: var(--white);
  width: 100%;
}
div#download-report input[type=text]:focus,
div#download-report input[type=email]:focus {
  outline: none;
  box-shadow: none;
}
div#download-report input[type=checkbox] {
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid var(--white);
  margin: 0;
  padding: 0;
  display: inline-grid;
  outline: none;
  transition: 0.2s;
}
div#download-report input[type=checkbox] {
  --border-radius: 0.3rem;
}
@supports (-webkit-appearance: none) or (appearance: none) {
  div#download-report input[type=checkbox] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  div#download-report input[type=checkbox]:checked {
    background: var(--white);
    width: 1.2rem;
    height: 1.2rem;
  }
  div#download-report input[type=checkbox]::after {
    content: "";
    width: 0.3rem;
    height: 0.7rem;
    border-radius: 0;
    border: 2px solid var(--primary-rose-red);
    border-top: 0;
    border-left: 0;
    transition: 0.3s;
    transform: translateY(-0.1rem) rotate(0deg);
    place-self: center;
    opacity: 0;
  }
  div#download-report input[type=checkbox]:checked::after {
    opacity: 1;
    transform: translateY(-0.1rem) rotate(45deg);
  }
}
div#download-report .btn__primary_sm {
  border: 1px solid var(--white);
}
div#download-report .btn__primary_sm::after {
  filter: unset;
}
div#download-report .btn__primary_sm:hover {
  color: var(--white);
}

/*--------------------------------------------------------------
# Plugins
--------------------------------------------------------------*/
/* Jetpack infinite scroll
--------------------------------------------- */
/* Hide the Posts Navigation and the Footer when Infinite Scroll is in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  display: none;
}

/* Re-display the Theme Footer when Infinite Scroll has reached its end. */
.infinity-end.neverending .site-footer {
  display: block;
}

/*--------------------------------------------------------------
# Utilities
--------------------------------------------------------------*/
/* Accessibility
--------------------------------------------- */
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

/* Do not show the outline on the skip link target. */
#primary[tabindex="-1"]:focus {
  outline: 0;
}

/* Alignments
--------------------------------------------- */
/* Classes
--------------------------------------------- */
/* Background colors
--------------------------------------------- */
.white {
  background-color: var(--white);
}

.rose-red {
  background-color: var(--primary-rose-red);
}

.purple-night {
  background-color: var(--primary-purple-night);
}

.blue-green {
  background-color: var(--primary-blue-green);
}

.dark-grey {
  background-color: var(--primary-dark-grey);
}

.whisp-pink {
  background-color: var(--primary-whisp-pink);
}

.light-grey {
  background-color: var(--primary-light-grey);
}

.saffron-yellow {
  background-color: var(--secondary-saffron-yellow);
}

.apple-green {
  background-color: var(--secondary-apple-green);
}

.green-sheen {
  background-color: var(--secondary-green-sheen);
}

.pink {
  background-color: var(--secondary-pink);
}

.dark-blue {
  background-color: var(--secondary-dark-blue);
}

.dark-magenta {
  background-color: var(--dark-magenta);
}

.medium-violet-red {
  background-color: var(--medium-violet-red);
}

.hot-pink {
  background-color: var(--hot-pink);
}

/* Font colors
--------------------------------------------- */
.tx-white {
  color: var(--white);
}

.tx-red {
  color: var(--primary-rose-red);
}

.tx-dark {
  color: var(--primary-dark-grey);
}

/* Font weights
--------------------------------------------- */
.bold {
  font-weight: var(--bold);
}

.semibold {
  font-weight: var(--semibold);
}

.regular {
  font-weight: var(--regular);
}

.xl-header {
  text-transform: uppercase;
  font-size: var(--font-size-xxxl);
  color: var(--primary-rose-red);
  font-weight: var(--bold);
}
@media screen and (max-width: 576px) {
  .xl-header {
    font-size: 2.5rem;
  }
}

.main-header {
  font-size: 2.5rem;
  font-weight: var(--bold);
}
@media screen and (max-width: 576px) {
  .main-header {
    font-size: 1.8rem;
  }
}

/* Background colors course-card
--------------------------------------------- */
.card-green {
  background-color: var(--primary-blue-green);
}

.card-red {
  background-color: var(--primary-rose-red);
}

.card-purple {
  background-color: var(--primary-purple-night);
}

.card-pink {
  background-color: var(--primary-pink);
}

.card-white {
  background-color: transparent;
}

/* For burger menu & overlay
--------------------------------------------- */
.body-no-scroll {
  overflow: hidden;
}

/* Spin Animation
--------------------------------------------- */
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Components
--------------------------------------------- */
.pink-text-box {
  background-color: var(--secondary-pink);
}

/* Container max-width
--------------------------------------------- */
.max-w-1000 {
  max-width: 1000px;
}