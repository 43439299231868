@font-face {
  font-family: helvetica-neue;
  src:
    format("woff"), url(../fonts/HelveticaNeue.woff) format("woff"), url(../fonts/HelveticaNeue.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

// @font-face{
//   font-family:helvetica-neue;
//   src:
//   url(../fonts/HelveticaNeue-Italic.woff) format("woff"),
//   url(../fonts/HelveticaNeue-Italic.ttf) format("truetype");
//   font-weight:400;
//   font-style:italic;
// }

@font-face {
  font-family: helvetica-neue;
  src:
    url(../fonts/HelveticaNeue-Medium.woff) format("woff"),
    format("woff"), url(../fonts/HelveticaNeue-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: helvetica-neue;
  src:
    url(../fonts/helvetica-neue/HelveticaNeue-Bold.woff) format("woff"),
    format("woff"), url(../fonts/HelveticaNeue-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

// @font-face{
//   font-family:helvetica-neue;
//   src:
//   url(../fonts/HelveticaNeue-MediumItalic.woff) format("woff"),
//   url(../fonts/HelveticaNeue-MediumItalic.ttf) format("truetype");
//   font-weight:500;
//   font-style:italic;
// }

/* Montserrat Regular */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Regular.woff) format("woff"),
    format("woff"), url(../fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Montserrat Medium */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Medium.woff) format("woff"),
    format("woff"), url(../fonts/Montserrat-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Montserrat Bold */
@font-face {
  font-family: Montserrat;
  src: url(../fonts/Montserrat-Bold.woff) format("woff"),
    format("woff"), url(../fonts/Montserrat-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

:root {
  --font-body: Montserrat, helvetica-neue, arial, sans-serif;
}