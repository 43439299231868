

// Buttons 

.btn__primary_sm{
    width: 190px;
    height: 57px;
    background-color: var(--primary-rose-red);
    border: 1px solid var(--primary-rose-red);
    color: var(--white);
    transition: 0.2s;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after{
        float: right;
        content: "";
        background-image: url(../img/icons/applaus-btn-arrow.svg);
        background-size: cover;
        width: 22px;
        height: 22px;
        margin-left: 10px;
        transition: 0.2s;
        filter: brightness(0) invert(1);
    }

    &:hover{
        background-color: transparent;
        color: var(--primary-rose-red);

        &::after{
            transform: rotate(45deg);
            filter: unset;
        }
    }
   
}

.btn__secondary_sm{
    width: 190px;
    height: 57px;
    background-color: transparent;
    border: 1px solid var(--primary-rose-red);
    color: var(--primary-rose-red);
    transition: 0.2s;
    text-decoration: none;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after{
        float: right;
        content: "";
        background-image: url(../img/icons/applaus-btn-arrow.svg);
        background-size: cover;
        width: 22px;
        height: 22px;
        margin-left: 10px;
        transition: 0.2s;
    }

    &:hover{
        background-color: var(--primary-rose-red);
        color: var(--white);

        &::after{
            transform: rotate(45deg);
            filter: brightness(0) invert(1);
        }
    }
}

.btn__tertiary_sm{
    background-color: transparent;
    border: none;
    color: var(--primary-rose-red);
    font-weight: var(--bold);
    text-decoration: none;

    &::after{
        float: right;
        content: "";
        background-image: url(../img/icons/applaus-btn-arrow.svg);
        background-size: cover;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        transition: 0.2s;
    }

    &:hover{
        color: var(--primary-rose-red);
        &::after{
            transform: rotate(45deg);
        }
    }

    @media screen and (max-width: 992px){
        &::after{
            width: unset;
        }
    }
}

.btn__primary_lg{
    width: 30rem;
    height: 6rem;
    font-size: var(--font-size-md);
    border: 1px solid var(--primary-rose-red);
    background-color: var(--primary-rose-red);
    box-shadow: 0px 23px 40px #E1295B33;
    color: var(--white);
    transition: .2s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;


    &:hover{
        border: 1px solid var(--primary-rose-red);
        background-color: transparent;
        color: var(--primary-rose-red);
        box-shadow: revert;
        transition: .2s;
        color: var(--primary-rose-red);
 
    }
}

.btn__secondary_lg{
    width: 30rem;
    height: 6rem;
    font-size: var(--font-size-md);
    background-color: transparent;
    border: 1px solid var(--primary-rose-red);
    color: var(--primary-rose-red);
    transition: .2s;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        color: var(--white);
        background-color: var(--primary-rose-red);
        
    }
}


@media screen and (max-width:576px){
    .btn__primary_lg, 
    .btn__secondary_lg{
        width: 100%;
    }
}

.btn-w-100 {
    .btn__secondary_sm,
    .btn__primary_sm{
        width: 100%;
    }
    .btn__secondary_sm:hover{
        color: var(--primary-rose-red);
    }
}






