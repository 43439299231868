
footer.site-footer{

    div.subscription-cta-wrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;


        div.col-left{
            grid-area: 1/1/2/3;
        }

        div.col-right{
            grid-area: 1/2/2/3;
        }
    }
   
    img.image-logo{
        width: 50%;
    }

    img.footer-logo-img{
        width: 50%;
    }

    img.footer-some__icon{
        width: 14%;
        margin-right: 4px;
    }

    .footer-col{

        &__ul{
            padding: 0;
        }

        &__li{
            list-style: none;
        }

        &__content p{
            font-size: var(--font-size-md);
        }
    }

    a.footer-link{
        color: var(--primary-dark-grey);
        text-decoration: none;

        &:hover{
            color: var(--primary-rose-red);
        }
    }


    a.footer-link, .footer-col__content p {
        font-size: 1.2rem;
    }

    form{

        input{
            border: none;
            border-bottom: 1px solid var(--primary-rose-red);

            &:focus{
                outline: none; 
                background-color: var(--primary-light-grey);
            }
        }

        input[type="text"]{
            width: 100%;
        }

        input[type="email"]{
            width: 100%;
        }

        input[type="submit"]{
            &:hover{
                border: 1px solid var(--primary-rose-red);
            }
        }

        label{
            color: var(--primary-rose-red);
            display: block;

            a{
                color: var(--primary-rose-red);  
            }
        }




        // ----------- checkbox ------ //

        input[type="checkbox"] {
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid var(--primary-rose-red);
            background: hsl(200 50% 100%);
            margin: 0;
            padding: 0;
            display: inline-grid;
            outline: none;
            transition: 0.2s;
        }
    
        input[type="checkbox"] {
            --border-radius: 0.3rem;
        }
    
        @supports (-webkit-appearance: none) or (appearance: none) {
            input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            }
        
            input[type="checkbox"]:checked {
            background: var(--primary-rose-red);
            width: 1.2rem;
            height: 1.2rem;
            }
        
            input[type="checkbox"]::after {
            content: "";
            width: 0.3rem;
            height: 0.7rem;
            border-radius: 0;
            border: 2px solid var(--white);
            border-top: 0;
            border-left: 0;
            transition: 0.3s;
            transform: translateY(-0.1rem) rotate(0deg);
            place-self: center;
            opacity: 0;
            }
        
            input[type="checkbox"]:checked::after {
            opacity: 1;
            transform: translateY(-0.1rem) rotate(45deg);
            }
        }

        

    }


    .wpcf7 form.sent .wpcf7-response-output {
        border: 1px solid var(--primary-rose-red);
    }


    @media screen and (min-width: 1600px){

        img.subscription-cta__img{
            max-height: 80%;
            object-fit: cover;
        }

    }


    @media screen and (max-width: 992px){

        a.footer-link, .footer-col__content p {
            font-size: 1.2rem;
        }

        img.footer-some__icon{
            width: 6%;
        }

        img.image-logo {
            width: 30%;
        }

        img.footer-logo-img{
            width: 25%;
        }
    }


    @media screen and (max-width: 768px){

    
        img.footer-some__icon{
            width: 10%;
        }

    }


    @media screen and (max-width: 576px){

        img.footer-some__icon{
            width: 12%;
        }

        img.image-logo {
            width: 50%;
        }
    }
}



.subscription-cta{

    &__{
        width: 100%;
    }
}

// ----------- WPCF7 ------ //

.wpcf7-list-item{
    margin: 0;
}

.wpcf7 form.invalid .wpcf7-response-output, 
.wpcf7 form.unaccepted .wpcf7-response-output, 
.wpcf7 form.payment-required .wpcf7-response-output {
    border: none;
    padding: 0;
    margin: 0;
    color: var(--primary-rose-red);
}
