
/* Background colors
--------------------------------------------- */

.white{
    background-color: var(--white);
}

.rose-red{
    background-color: var(--primary-rose-red);
}

.purple-night{
    background-color: var(--primary-purple-night);
}

.blue-green{
    background-color: var(--primary-blue-green);
}

.dark-grey{
    background-color: var(--primary-dark-grey);
}

.whisp-pink{
    background-color: var(--primary-whisp-pink);
}

.light-grey{
    background-color: var(--primary-light-grey);
}

.saffron-yellow{
    background-color: var(--secondary-saffron-yellow);
}

.apple-green{
    background-color: var(--secondary-apple-green);
}

.green-sheen{
    background-color: var(--secondary-green-sheen);
}

.pink{
    background-color: var(--secondary-pink);
}

.dark-blue{
    background-color: var(--secondary-dark-blue);
}

.dark-magenta {
    background-color: var(--dark-magenta);
}

.medium-violet-red {
    background-color: var(--medium-violet-red);
}

.hot-pink {
    background-color: var(--hot-pink);
}

/* Font colors
--------------------------------------------- */

.tx-white{
    color: var(--white);
}

.tx-red{
    color: var(--primary-rose-red);
}

.tx-dark{
    color: var(--primary-dark-grey);
}



/* Font weights
--------------------------------------------- */

.bold{
    font-weight: var(--bold);
}

.semibold{
    font-weight: var(--semibold);
}

.regular{
    font-weight: var(--regular);
}

.xl-header{
    text-transform: uppercase;
    font-size: var(--font-size-xxxl);
    color: var(--primary-rose-red);
    font-weight: var(--bold);


    @media screen and (max-width:576px){
        font-size: 2.5rem;
    }
}

.main-header{
    font-size: 2.5rem;
    font-weight: var(--bold);

    @media screen and (max-width:576px){
        font-size: 1.8rem;
    }
}


/* Background colors course-card
--------------------------------------------- */

.card-green{
    background-color: var(--primary-blue-green);
}

.card-red{
    background-color: var(--primary-rose-red);
}

.card-purple{
    background-color: var(--primary-purple-night);
}

.card-pink{
    background-color: var(--primary-pink);
}

.card-white{
    background-color: transparent;
}



/* For burger menu & overlay
--------------------------------------------- */
.body-no-scroll{
    overflow: hidden;
}



/* Spin Animation
--------------------------------------------- */

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
        
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


/* Components
--------------------------------------------- */

.pink-text-box{
    background-color: var(--secondary-pink);
}

/* Container max-width
--------------------------------------------- */

.max-w-1000{
    max-width: 1000px;
}