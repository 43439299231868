
section.post-archive-block{

    // Style for the section header

    div.section-header{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        align-items: end;

        strong{
            color: var(--primary-rose-red);
            text-decoration: underline;
        }
    }

    div.col-left{
        grid-area: 1/1/2/3;
        align-self: center;
    }

    div.col-right{
        grid-area: 1/4/2/5;
    }

    // img.arrow-xxl{
    //     width: 120%;
    // }


    @media screen and (max-width:576px){
        div.col-left{
            grid-area: 2/1/2/5;
        }

        img.arrow-xxl{
            width: 100%;
        }

    }
}