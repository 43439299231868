.related-posts-block{

    &.frontpage{
        div.post-header{
            .tx-red{
                text-transform: uppercase;
                font-size: var(--font-size-xxxl);
            }
        }
    }

    @media screen and (max-width:1200px){

        .kursus-archive{
            div.post-item-card:nth-child(3){
                display: none;
            }
        }

    }

    @media screen and (max-width:992px){

        .btn__tertiary_sm svg {
            width: 5%;
        }

        .kursus-archive{
            div.post-item-card:nth-child(3){
                display: none;
            }
        }

        div.post-item-card:nth-child(3){
            display: none;
        }

    }

    @media screen and (max-width:768px){

        div.post-item-grid {
            grid-template-columns: repeat(2, 1fr);
        }


        div.post-item-card{
            display: block;

        }
        

    }

    @media screen and (max-width:576px){

        div.post-item-grid {
            grid-template-columns: auto;
        }

        div.post-item-card{
            // display: none;

            img.post-image {
                height: 200px;
            }

            .post-item-header {
                font-size: 1.2rem;
            }
        }

        .kursus-archive{
            div.post-item-card:nth-child(2){
                display: none;
            }
        }

        div.post-item-card:nth-child(2){
            display: none;
        }


        .link-text{
            font-size: 1.1rem;
        }
    }

}