section.literature-overview-block{

    div.grid-literature{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;    
    }

    div.literature-post{
        box-shadow: var(--box-shadow-literature);
        border-left: 6px solid var(--primary-rose-red);

        a.post-item-link{
            text-decoration: none;
            color: var(--primary-dark-grey);
        }  

        .post-item-header{
            font-size: 1.1rem;
            color: var(--primary-rose-red);
            font-weight: var(--bold);
            min-height: 50px;
        }

        .card-category{
            color: var(--grey-text);
            font-size: 1rem;
        }

        ul.card-subcategory{
            color: var(--grey-text-light);

            li{
                list-style: none;

                &:after{
                    content: ',';
                    margin-left: -4px;
                    margin-right: 4px;
                }

                &:last-child::after{
                    content: '';
                }
            }
        }

        .btn__secondary_sm{
            &:hover{
                background-color: var(--primary-rose-red);
                color: var(--white);
            }
        }
    }

    div.loader-overlay{
        height: 50vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        div.loader {
            border: 6px solid #f3f3f3;
            border-radius: 50%;
            border-top: 6px solid #fffbfbeb;
            width: 4rem;
            height: 4rem;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
            margin-top: 6rem;
        }
            
        /* Safari */
        @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
        }
            
        @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }


        &.hide-loader{
            display: none;
        }
    }

    div.not-found-message{
        .category-item-all{
            color: var(--primary-rose-red);
            padding: 0.5rem 0;
        }
    }

    // div.sidebar-filter-wrapper{
    //     position: sticky;
    //     top: 6rem;
    // }

    div.search-group{

        input{
            border: none;
            outline: none;
            border-bottom: 1px solid var(--primary-rose-red);


            &:focus{
                outline: none; 
            }
        }

        label{
            color: var(--primary-rose-red);
        }

       .btn__secondary_sm{
            height: fit-content;
            padding: 8px 0;
            outline: none;

            &:hover{
                color: var(--white);
                background-color: var(--primary-rose-red);
            }
        }

    }

    div.filter-header-wrapper{
        background-color: var(--primary-rose-red);
        color: var(--white);

        .filter-header{
            font-size: 1rem;
        }

        img{
            width: 4%;
            filter: brightness(0) invert(1);
        }
    }

    button.btn-toggle-filter-mobile{
        background-color: var(--primary-rose-red);
        color: var(--white);
        border: none;
        outline: none;
        height:  2.7rem;
        width: 6rem;

        img{
            width: 20%;
            margin-left: 6px;
            filter: brightness(0) invert(1);
        }
    }

    //CSS for the accordion

    .accordion-item{

        border: unset;
        border-top: 1px solid var( --border-line);
        &:first-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        &:last-of-type {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .accordion-body{
        label{
            font-size: .9rem;
        }
    }

    .accordion-button{
        color: var(--primary-dark-grey);
        background-color: transparent;
        box-shadow: none;

        &::after{
            background-image: url(../img/icons/angle-small-down-dark.svg);
            background-size: 1rem; 
            width: 1rem;
            height: 1rem;
        }
    }

    .category-item-all{
        color: var(--primary-rose-red);
        background-color: transparent;
        font-weight: var(--bold);
        border: none;
        outline: none;
        padding: .5rem 0;

        &:hover{
          
            color: var(--primary-dark-grey);
        }
    }


     // ----------- checkbox ------ //

     input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
        // border: 1px solid var(--primary-rose-red);
        border: 1px solid var(--primary-dark-grey);
        background: hsl(200 50% 100%);
        margin: 0;
        padding: 0;
        display: inline-grid;
        outline: none;
        transition: 0.2s;
    }

    input[type="checkbox"] {
        --border-radius: 0.3rem;
    }

    @supports (-webkit-appearance: none) or (appearance: none) {
        input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
        }
    
        input[type="checkbox"]:checked {
            border: 4px solid var(--primary-rose-red);
        }

    }

    // ----------- checkbox ends ------ //

    // CSS for the filter list on mobile
    div.literature-filter-menu-mobile{
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 110;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var( --primary-rose-red);
        color: var(--white);
        visibility: hidden;
        opacity: 0;
        transform: scale(1.09);
        transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
        box-sizing: border-box;
    
    
        &.active{
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }

    @media screen and (max-width:1200px){
        div.grid-literature{
            grid-template-columns: auto;
        }

        div.literature-post{
            .post-item-header{
                min-height: auto;
            }
        }
    }

    @media screen and (max-width: 767px){

        div.sidebar-filter-wrapper{
            margin-bottom: 10rem;
        }

        .accordion-item{
            background-color: var(--primary-rose-red);
            border-top: 1px solid var(--white);
        }

        .accordion-button {
            color: var(--white);
        }

        .accordion-button{
            &::after{
                filter: brightness(0) invert(1);
            }
        }

        .category-item-all {
            color: var(--white);
            border: 1px solid var(--white);
            padding: 0.5rem;
        }

        input[type=checkbox] {
            background: transparent;
            border: 1px solid var(--white);
        }

        @supports (-webkit-appearance: none) or (appearance: none) {
        
            input[type="checkbox"]:checked {
                border: 4px solid var(--white);
            }
        }
    }


}




//CSS for Literarure single page

div.sidebar-details{
    box-shadow: var(--box-shadow-literature);
    border-top: 8px solid var(--primary-rose-red);
    border-bottom: 8px solid var(--primary-rose-red);

    .card-category{
        font-size: 1rem;
    }

    ul.card-subcategory{
        color: var(--grey-text-light);

        li{
            list-style: none;

            &:after{
                content: ',';
                margin-left: -4px;
                margin-right: 4px;
            }

            &:last-child::after{
                content: '';
            }
        }
    }

}