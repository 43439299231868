
section.post-single-page{

    img.post-hero-image {
        object-fit: cover;
    }

    span.category {
        position: unset;
    }

    div.summary p{
        font-size: 1.2rem;
    }

    a:not(.btn__primary_sm):not(.btn__primary_lg):not(.btn__secondary_sm):not(.btn__secondary_lg):not(.btn__tertiary_sm):not(.btn__tertiary_lg) {
        color: var(--primary-rose-red);
    }

    div.sidebar-wrapper {
        box-shadow: var(--box-shadow-dark);

        .tx-red-lg {
            color: var(--primary-rose-red);
            text-transform: uppercase;
            font-size: 3rem;
            font-weight: var(--bold);
        }

        .tx-red {
            color: var(--primary-rose-red);
            text-transform: uppercase;
            font-size: 1.8rem;
            font-weight: var(--bold);
        }


        .btn-w-100 {
            .btn__secondary_sm,
            .btn__primary_sm{
                width: 100%;

                svg{
                    width: 6%;
                }
            }
            .btn__secondary_sm:hover{
                color: var(--white);
            }
        }

        .sidebar-img {
            max-width: 100%;
            display: block;
            margin: auto;
            max-height: 300px;
            width: auto;
        }
    }

    .sidebar-header{
        background-color: var(--primary-dark-grey);
        color: var(--white);
    }

    .wp-block-image{
        margin-block: 2rem;
    }


    @media screen and (max-width:1400px){

        div.sidebar-wrapper{
            .tx-red-lg{
                font-size: 2.5rem;
            }
        }
    }


    @media screen and (max-width:1200px){
        div.sidebar-wrapper{
            .tx-red-lg{
                font-size: 2rem;
            }
        }
    }

    @media screen and (max-width:768px){
        img.post-hero-image{
            height: 40vh;
        }
        div.sidebar-wrapper .sidebar-img {
            max-height: 200px;
        }
    }


    @media screen and (max-width:576px){
        div.sidebar-wrapper {   
            .tx-red {
                font-size: 1.6rem;
            }
        }

        div.sidebar-container{
            padding: 0;
        }
    }
}