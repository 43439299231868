section.courses-cta{


    div.cta-item{
        height: 26rem;
    }

    div.cta-text{
        height: 6rem;
    }

    img.cta-img{
        width: 25%;
    }

    div.item-1{
        background-color: var(--primary-pink); 
        color: var(--primary-rose-red);
    }

    div.item-2{
        background-color: var(--primary-rose-red);
        color: var(--white);
    }

    div.item-3{
        background-color: var(--primary-dark-grey);
        color: var(--white);
    }


    .btn__secondary_sm{
        border: 1px solid var(--white);
        color: var(--white);

        &:hover{
            background-color: transparent;
        }

        &::after{
            filter: brightness(0) invert(1);
        }
 
    }


    @media screen and (max-width:576px){
        .btn__primary_sm, .btn__secondary_sm{
            width: 100%;
        }

        img.cta-img{
            width: 35%;
        }
    }
}

