
section.frontend-hero{
    overflow: hidden;

    div.frontend-hero-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    div.col-left{
        grid-area: 1/1/2/3;
    }

    div.col-right{
        grid-area: 1/2/2/3;
    }

    div.hero-grid{
        display: grid;

        &>*{
            grid-area: 1/1/2/2;
        }
    }

    video{
        height: 60vh;
        width: 100%;
        object-fit: cover;
    }

    a.svg-wrapper{
        align-self: end;
        margin-left: -2rem;
        margin-bottom: -2rem;
        cursor: pointer;
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        border: 1px solid var(--primary-dark-grey);;
        padding: 18px;
        background-color:var(--white);
        display: grid;
        justify-items: center;
        align-items: center;
        

        &>*{
            grid-area: 1/1/2/2;
        }

        svg{
            height: auto;
            max-width: 100%;
            width: 8rem;
            transform-origin: center center;
            transform: rotate(30deg);
            overflow: visible;
            letter-spacing: 3px;
            fill: var(--primary-dark-grey);
            animation: spin 40s linear infinite;
        }

        img.arrow{
            width: 44%;
            transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
        }


        &:hover{
            img.arrow{
                transform: translate(10px, -10px);
            }
        }
    }


    @media screen and (max-width:1200px){

        div.frontend-hero-grid{
            grid-template-columns: auto;
        }
    
        div.col-left{
            grid-area: 2/1/3/2;
        }
    
        div.col-right{
            grid-area: 1/1/2/2;
        }

        a.svg-wrapper{
            justify-self: end;
            margin-right: 2rem;
        }

    }


    @media screen and (max-width: 768px){

        a.svg-wrapper{
            justify-self: end;
            margin-left: 0;
            margin-bottom: 3rem;
        }

        video, .bg-video {
            clip-path: polygon(0% 0%, 100% 0%, 100% 76%, 0% 100%);
            z-index: -1;
        }

        .hero-grid {
            position: relative;
            height: 65vh;
            width: 100%;
        }

        .bg-video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%;
            width: 118vh;
        }
    }


    @media screen and (max-width:450px){
        a.svg-wrapper{
            margin: 0;
            justify-self: center;
            margin-left: 12rem;
        }

        .btn__primary_sm{
            width: 100%;
        }
    }


}