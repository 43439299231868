
div.course-card{
    position: relative;

    div.img-wrapper{
        display: grid;
        color: var(--white);

        &>*{
            grid-area: 1/1/2/2;
        }

        img.post-image{
            mix-blend-mode: darken;
        }

        h3{
            align-self: center;
            justify-self: center;
            z-index: 1;
        }

    }


    span.gratis-label{
        color: var(--white);
        z-index: 1;
        background-color: var(--primary-dark-grey);
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -2%;
        text-transform: uppercase;
        padding: 4px 10px;
        font-size: 10px;
        letter-spacing: 0.4px;
    }

    div.price{
        background-color: var(--primary-rose-red);
        height: 50px;
        min-width: 50%;
        width: fit-content;
        padding: 0 2.5rem 0 1.5rem;
        clip-path: polygon(0% 0%, 100% 0%, 88% 100%, 0% 100%);
        color: var(--white);
    }

    div.price-white{
        background-color: var(--white);
        height: 52px;
        margin-bottom: -2px;
        width: 57%;
        clip-path: polygon(0% 0%, 88% 0%, 100% 100%, 0% 100%);
    }

    span{
        font-size: 12px;
        text-transform: uppercase;
    }

    div.text-wrapper{
        box-shadow: var(--box-shadow-dark);
        background-color: var(--white);
    }

    div.text-wrapper-bottom{

        ul{
            list-style: none;
            padding-inline: 20px;
        }

        ul li.li-course:before {
            float: left;
            content: "";
            background-image: url(../img/icons/checkmark.svg);
            background-size: cover;
            position: relative;
            width: 16px;
            height: 12px;
            margin-left: -1.5rem;
            margin-top: 4px;
        }

        img.location-icon{
            width: 5%;
            margin-right: 0.5rem;
        }

    }


    .post-item-header__course{
        font-size: 1.5rem;
    }


    @media screen and (max-width:576px){
        span.gratis-label {
            font-size: 10px;
        }


        .post-item-header__course{
            font-size: 1.3rem;
        }
    }

}