section.buy-subscription{

    div.subscription-cta-wrapper{
        display: grid;
        grid-template-columns: 1fr;

        &>*{
            grid-area: 1/1/2/2;
        }

    }

    div.col-right{
        z-index: 11;

        a{
            color: var(--primary-rose-red);

            &:hover{
                color: var(--primary-dark-grey); 
            }
        }
    }


    @media screen and (min-width: 1600px){

        img.subscription-cta__img{
            max-height: 80%;
            object-fit: cover;
        }

    }

    @media screen and (min-width: 1200px){

        div.col-left{
            position: sticky;
            height: fit-content;
            top: 5rem;
        }

        div.col-right{
            justify-self: end;
        }

    }

    @media screen and (max-width: 1200px){

        div.col-right{
            grid-area: 2/1/3/2;
        }

    }
}