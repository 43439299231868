
section.course-network-single-page{

    a:not(.btn__primary_sm):not(.btn__primary_lg):not(.btn__secondary_sm):not(.btn__secondary_lg):not(.btn__tertiary_sm):not(.btn__tertiary_lg) {
        color: var(--primary-rose-red);
    }

    div.post-hero-image-banner{
        display: grid;
        color: var(--white);
        position: relative;
        overflow: hidden;

        &>*{
            grid-area: 1/1/2/2;
        }

    }

    img.post-hero-image{
        height: 74vh;
        object-fit: cover;
        mix-blend-mode: darken;
        max-height: 800px;
    }

    div.post-hero-text{
        align-self: center;
        z-index: 1;

    }

    .publikums-title{
        color: var(--primary-dark-grey);
    }

    div.price-box{
        .price{
            font-weight: var(--bold);
        }

        .price-currency{
            font-weight: 400;
            opacity: .8;
            font-size: 1rem;
        }
    }

    div.pink-box{
        background-color: var(--secondary-pink);
        color: var(--primary-rose-red);
    }

    div.main-content-wrapper{
        z-index: 1;

        img.image-left{
            width: 300px;
            height: 250px;
            object-fit: cover;
        }

    }

    div.sidebar-wrapper{
        box-shadow: var(--box-shadow-dark);
        height: fit-content;
        border-bottom: 8px solid var(--primary-rose-red);
        // position: sticky;
        top: 5rem;
        // z-index: -1;
        background-color: var(--white);

        .tx-red-lg{
            color: var(--primary-rose-red);
            text-transform: uppercase;
            font-size: 50px;
            font-weight: var(--bold);
        }

        p.label{
            font-weight: var(--bold);
        }

        .text-end {
            font-size: .8rem;
        }

        .btn__secondary_sm{
            &:hover{
                color: var(--white);
            }
    
        }

        .btn-w-100 {
            .btn__secondary_sm,
            .btn__primary_sm{
                width: 100%;

                svg{
                    width: 6%;
                }
            }
        }
    }

    div.menu-options-wrapper{
        border-bottom: 1px solid var(--border-line);
        background-color: var(--white);
        overflow: hidden;
        position: sticky;
        top: 0;
        z-index: 11;

        ul{
            background-color: var(--white);
            overflow: auto;
            white-space: nowrap;
            list-style: none;
        }

        li{
            margin-right: 1.8rem;
        }

        a{
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: none;
            color: var(--primary-dark-grey);

            &:hover{
                color: var(--primary-rose-red);
            }
            
            &:active{
                color: var(--primary-rose-red);
            }
        }

        div.bg-main{
            background-color: var(--primary-rose-red);
            color: var(--white);
        }
    }

    // Test css for the corner ribbon

    .cr-top-right {
        width: 45%;
        padding: 10px;
        position: absolute;
        text-align: center;
        color: var(--white);
        background-color: var(--primary-rose-red);
        top: 18%;
        right: -12%;
        transform: rotate(45deg);
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }


    @media screen and (max-width:1400px){

        div.sidebar-wrapper{
            .tx-red-lg{
                font-size: 40px;
            }
        }
    }


    @media screen and (max-width:1200px){

        div.sidebar-wrapper{
            .tx-red-lg{
                font-size: 30px;
            }
        }

        .cr-top-right {
            width: 55%;
            top: 15%;
            right: -15%;
            font-size: 16px;
        }
    }


    @media screen and (max-width:992px){

        .btn__tertiary_sm svg {
            width: 4%;
        }

        .cr-top-right {
            width: 80%;
            top: 12%;
            right: -22%;
        }
    }


    @media screen and (max-width:768px){

        img.post-hero-image{
            height: 40vh;
        }

        .cr-top-right {
            width: 72%;
            top: 22%;
            right: -22%;
        }
    }


    @media screen and (max-width:576px){


        div.post-hero-text{
            margin-top: 18rem;
        }

        div.sidebar-wrapper {
            margin: auto;
            border-top: 8px solid var(--primary-rose-red);
        }

        .btn__tertiary_sm svg {
            width: 8%;
        }

        .cr-top-right {
            width: 78%;
            top: 16%;
            right: -22%;
            font-size: 12px;
        }
    }
       

}

section.custom-network{

    img.post-hero-image{
        height: auto;
    }

    div.sidebar-wrapper {
        border-top: 8px solid var(--primary-rose-red);
        margin-top: -5.6rem;
        z-index: 22;
        top: 0;
    }

    @media screen and (max-width:1200px){
        div.sidebar-wrapper {
            margin-top: unset;
            z-index: unset;
        }
    }

    @media screen and (max-width:992px){
        .cr-top-right {
            width: 72%;
            top: 24%;
            right: -22%;
        }
    }

}