
section.frontpage-post-overview{

    div.report-card{
        position: relative;

        span.category{
            border: transparent;
            color: var(--white);
            padding: 4px 10px;
            font-size: 10px;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            position: absolute;
            margin-top: -1rem;
            right: 1rem;
            z-index: 99;
            min-width: 160px;
            text-align: center;
        }
    }


    .draggable{
        padding: 0 !important;
        overflow: visible;
    }

    @media screen and (max-width: 992px){
        overflow: hidden;
    }

    @media screen and (max-width: 768px){
        div.report-card{
    
            span.category{
                left: 0;
                right: unset;
            }
        }
    }


}
