h1, h2{
    font-weight: var(--bold);
}

h1{
    font-size: 2.5rem;
}

h2{
    font-size: 2.2rem;
}

h3{
    font-size: 1.8rem;
}

h4{
    font-size: 1.4rem;
}

h5{
    font-size: 1.2rem;
}

p{
    font-size: 1rem;
}


@media screen and (max-width:576px){
    h1{
        font-size: 1.8rem;
    }
}