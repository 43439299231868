
section.about-text-and-image{

    div.about-grid-image-right{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
    }

    div.col-left{
        grid-area: 1/1/2/3;
    }

    div.col-right{
        grid-area: 1/2/2/3;
    }

    .title{
        font-size: 60px;
    }

    .subtitle{
        font-weight: var(--bold);
    }

    div.description p{
        color: var(--primary-dark-grey);
    }

    img.about-img{
        object-fit: cover;
    }

    div.overlay{
        background-color: var(--body-overlay);
        height: 40vh;
    }


    @media screen and (max-width: 992px){

        div.about-grid-image-right{
            grid-template-columns: auto;
        }

        div.col-left{
            grid-area: 1/1/2/2;
        }

        div.col-right{
            grid-area: 2/1/3/2;
            display: grid;
            align-items: stretch;
            color: var(--white);
    
            &>*{
                grid-area: 1/1/2/2;
            }
        }

        img.about-img {
            height: 60vh; 
        }
    }


    @media screen and (max-width: 768px){
        img.about-img {
            height: 40vh; 
        }
    }


    @media screen and (max-width: 450px){
        div.col-right{
            padding: 0;
        }
    }
}