section.about-accordion{

    strong{
        color: var(--primary-rose-red);
    }

    .accordion-item {
        background-color: #fff;
        border: 1px solid var(--primary-rose-red);
        border-bottom: transparent;

        &:last-child{
            border-bottom: 1px solid var(--primary-rose-red);
        }

        &:first-of-type {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }

        &:last-of-type {
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .btn__primary_sm,
        .btn__secondary_sm{
            width: auto;
            padding-inline: 20px;
        }

        a, a span {
            color: var(--primary-rose-red);
            text-decoration: underline;
        }

    }

    .accordion-button{

        color: var(--primary-rose-red); 
        font-weight: var(--bold);
        background-color: var(--secondary-pink); 
        box-shadow: unset; 

        &::after{
            height: 2rem;
            outline: none;
            box-shadow: none;
            background-image: url(../img/icons/applaus-arrow.svg);
           
        }

        &:hover{
            background-color: var(--primary-rose-red);
            color: var(--white);

            &::after{
                filter: brightness(0) invert(1);
            }
        }

        &.collapsed{
            &::after{
                transform: rotate(0deg);
            }

        }

        &:not(.collapsed){
            background-color: var(--primary-rose-red);
            color: var(--white);

            &::after{
                transform: rotate(180deg);
                filter: brightness(0) invert(1);
            }
        }

    }
   
    button:focus:not(:focus-visible){
        outline: none;
        box-shadow: none;
    }

    .accordion-collapse{
        background-color: var(--secondary-pink);
    }

}

section.about-text-and-cta{
    .btn__secondary_sm{
        &:hover{
            color: var(--white);
        }
    }
}