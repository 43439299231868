
div.newsletter-popup-form{

    width: 200px;
    height: 3.5rem;
    position: fixed;
    right: 4%;
    bottom: 0;
    z-index: 99;
    transition: 0.4s;
    background-color: var(--primary-purple-night);


    &.active{

        height: auto;
        width: fit-content;
        transition: 0.4s;

        .active-none{
            display: block;
        }

        .popup-title{
            font-size: 1.4rem;
            margin-top: 1rem;
            margin-left: 0;
        }
    }


    form{
        background-color: var(--primary-purple-night);
        color: var(--white);
        padding: 1.4rem;


        input[type="text"],
        input[type="email"]{
            background-color: transparent;
            outline: none;
            border: none;
            border-bottom: 1px solid #ffffffb0;
            color: var(--white);
        }


         // ----------- checkbox ------ //

        input[type="checkbox"] {
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid var(--white);
            background: transparent;
            margin: 0;
            padding: 0;
            display: inline-grid;
            outline: none;
            transition: 0.2s;
        }
    
        input[type="checkbox"] {
            --border-radius: 0.3rem;
        }
    
        @supports (-webkit-appearance: none) or (appearance: none) {
            input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            }
        
            input[type="checkbox"]:checked {
            background: var(--white);
            width: 1.2rem;
            height: 1.2rem;
            }
        
            input[type="checkbox"]::after {
            content: "";
            width: 0.3rem;
            height: 0.7rem;
            border-radius: 0;
            border: 2px solid var(--primary-purple-night);
            border-top: 0;
            border-left: 0;
            transition: 0.3s;
            transform: translateY(-0.1rem) rotate(0deg);
            place-self: center;
            opacity: 0;
            }
        
            input[type="checkbox"]:checked::after {
            opacity: 1;
            transform: translateY(-0.1rem) rotate(45deg);
            }
        }
    }

    p{
        margin-bottom: 0;
    }

    .wpcf7-response-output {
        border: 2px solid var(--white);
        width: 300px;
        margin: 0;
        border: none;
        padding-left: 0;
    }

   .wpcf7-not-valid-tip{
       color: var(--white);
   }

    .popup-close-btn{
        cursor: pointer;
        display: block;

        &:after{
            content: "";
            background-image: url(../img/icons/popup-close-btn.svg);
            width: 26px;
            height: 26px;
            margin-right: -5px;
            margin-top: -5px;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    .popup-title{
        cursor: pointer;
        font-size: 1rem;
        margin-top: -0.6rem;
        margin-left: 1rem;
        text-align: center;

        &:after{
            content: "";
            background-image: url(../img/icons/paper-plane.svg);
            background-size: cover;
            position: relative;
            width: 16px;
            height: 16px;
            margin-left: 0.5rem;
            margin-top: 4px;
            display: inline-block;
        }
    }

    span.wpcf7-list-item-label{
        a{
            color: var(--white);
        }
    }

    .active-none{
        display: none;
    }

    .btn__secondary_sm {
        color: var(--white);
        border: 1px solid var(--white);

        &:hover{
            background-color: transparent;
        }
    }



    @media screen and (max-width:768px){
        display: none;
        &.active{
            height: 100%;
            width: 100%;
            right: 0;
            background-color: var(--primary-purple-night);
            display: grid;
            grid-template-rows: 10% 90%;
            align-items: center;
            justify-content: center;
        }

        .popup-close-btn{
            display: none;
        }
    

    }

    .wpcf7 form.invalid .wpcf7-response-output, 
    .wpcf7 form.unaccepted .wpcf7-response-output, 
    .wpcf7 form.payment-required .wpcf7-response-output {
        color: var(--white);
}


}

// Style for the open button on mobile
div.popup-open-btn-mobile{
    position: fixed;
    bottom: 1%;
    right: 1%;
    background-color: var(--primary-purple-night);
    padding: 18px;
    border-radius: 50px;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    cursor: pointer;
}

div.popup-close-btn-mobile{
    width: 16%;
    justify-self: end;
}

@media screen and (max-width:768px){
    div.popup-close-btn-mobile{
        margin-top: 16rem;
    }
}

@media screen and (max-width:576px){
    div.popup-close-btn-mobile{
        margin-top: 10rem;
    }
}