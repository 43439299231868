
div.post-item-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;    

    @media screen and (max-width:1200px){
        grid-gap: 10px;

        &.kursus-archive,
        &.netvaerksmode-archive{
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }

        
    }

    @media screen and (max-width:992px){
        width: 100%;
        grid-template-columns: repeat(2, 1fr);
        
    }

}


div.post-item-card{
    a.post-item-link{
        text-decoration: none;
        color: var(--primary-dark-grey);

        div.text-wrapper{
            .excerpt{
                font-size: var(--font-size-sm);
                min-height: 75px;
            }
        }
    }    

    img.post-image {
        object-fit: cover;
        height: auto;
        aspect-ratio: 200/107;
    }

    .post-item-date{
        opacity: .7;
    }

    .post-item-header{
        font-size: 1.3rem;

    }
}



@media screen and (max-width:1200px){

    a.post-item-link{
        display: grid;

        img.post-image,
        span.category{
            grid-area: 1/1/2/2;
        }

        div.text-wrapper {
            min-height: auto;
            z-index: 1;
            grid-area: 2/1/3/2;
        }
    }

}

@media screen and (max-width:768px){
    div.post-item-card{
        .post-item-header{
            font-size: 1.2rem;
        }
    }

    .btn__secondary_sm {
        width: 96%;
        margin: auto;
    }

    h1.xl-header{
        font-size: 2.3rem;
    }

    .post-item-date{
        font-size: .75rem;
    }
}

@media screen and (max-width:576px){
    div.post-item-card{
        .post-item-header{
            font-size: .8rem;
        }

        &.vaerktoj-card{
            img.post-image {
                height: 90px;
            }
        }

    }


    .netvaerksmode-archive{
        div.post-item-card {
            .post-item-header {
                font-size: 1rem;
            }
        }
    }


    h1.xl-header{
        font-size: 1.5rem;
    }

    .see-more-btn{
        font-size: 1rem;
        width: 180px;
    }

}


