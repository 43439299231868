
section.report-news-overview-block{

    button.category-item{
        border: transparent;
        color: var(--white);
        padding: 4px 10px;
        font-size: 10px;
        letter-spacing: .4px;
        text-transform: uppercase;
        margin-bottom: 2px;
        min-width: 160px;
        text-align: center;
    }

    button.all{
        color: var(--primary-dark-grey);
        border: 1px solid var(--primary-dark-grey);
        background-color: transparent;
        box-shadow: var(--box-shadow-dark);
    }

    button.purple-night{
        box-shadow: var(--box-shadow-purple);
        border: 1px solid var(--primary-purple-night);

        &__active{
            background-color: transparent;
            color: var(--primary-purple-night);
        }
    }

    button.blue-green{
        box-shadow: var(--box-shadow-green);
        border: 1px solid var(--primary-blue-green);

        &__active{
            background-color: transparent;
            color: var(--primary-blue-green);
        }
    }

    button.rose-red{
        box-shadow: var(--box-shadow-red);
        border: 1px solid var(--primary-rose-red);

        &__active{
            background-color: transparent;
            color: var(--primary-rose-red);
        }
    }

    button.dark-grey{
        box-shadow: var(--box-shadow-dark);
        border: 1px solid var(--primary-dark-grey);

        &__active{
            background-color: transparent;
            color: var(--primary-dark-grey);
        }
    }

    button.dark-blue{
        box-shadow: var(--box-shadow-dark-blue);
        border: 1px solid var(--secondary-dark-blue);

        &__active{
            background-color: transparent;
            color: var(--secondary-dark-blue);
        }
    }

    div.loader-overlay{
        height: 50vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        div.loader {
            border: 6px solid #f3f3f3;
            border-radius: 50%;
            border-top: 6px solid #fffbfbeb;
            width: 4rem;
            height: 4rem;
            -webkit-animation: spin 2s linear infinite; /* Safari */
            animation: spin 2s linear infinite;
            margin-top: 6rem;
        }
          
        /* Safari */
        @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
        }
          
        @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
        }


        &.hide-loader{
            display: none;
        }
    }

    // CSS for the filter list on mobile
    div.filter-menu-mobile{
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 110;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var( --primary-rose-red);
        color: var(--white);
        visibility: hidden;
        opacity: 0;
        transform: scale(1.09);
        transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
        box-sizing: border-box;
    
    
        &.active{
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }

    form{

        // ----------- checkbox ------ //

        input[type="checkbox"] {
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid var(--white);
            // background: hsl(200 50% 100%);
            margin: 0;
            padding: 0;
            display: inline-grid;
            outline: none;
            transition: 0.2s;
        }
    
        input[type="checkbox"] {
            --border-radius: 0.3rem;
        }
    
        @supports (-webkit-appearance: none) or (appearance: none) {
            input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            }
        
            input[type="checkbox"]:checked {
            background: var(--white);
            width: 1.2rem;
            height: 1.2rem;
            }
        
            input[type="checkbox"]::after {
            content: "";
            width: 0.3rem;
            height: 0.7rem;
            border-radius: 0;
            border: 2px solid var(--primary-rose-red);
            border-top: 0;
            border-left: 0;
            transition: 0.3s;
            transform: translateY(-0.1rem) rotate(0deg);
            place-self: center;
            opacity: 0;
            }
        
            input[type="checkbox"]:checked::after {
            opacity: 1;
            transform: translateY(-0.1rem) rotate(45deg);
            }
        }

    }
    // End CSS for the filter list on mobile

    .hide-btn{
        visibility: hidden;
    }


}




// CSS for the report category span

span.category{
    border: transparent;
    color: var(--white);
    padding: 4px 10px;
    font-size: 10px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    position: absolute;
    margin-top: -1rem;
    right: 1rem;
    z-index: 99;
    min-width: 160px;
    text-align: center;
    font-weight: var(--bold);
}

@media screen and (max-width:992px){
    span.category{
        right: unset;
        left: 0;
        font-weight: var(--normal);
    }
}

@media screen and (max-width: 576px){
    span.category{
        font-size: 9px;
    }
}