
section.testimonial-slider-section{
    
    div.testimonial-slider{
        display: grid;  
    }     

    div.testimonial-item{
        color: var(--white);

        &>*{
            opacity: .9;
        }

        .job_title{
            font-size: var(--font-size-sm);
        }

        .user-testimonial{
            border-bottom: 1px solid;
            font-style: italic;
        }
    }
    
    button.slick_btn{
        grid-area: 1/1/2/2;
        margin-top: -105px;
        justify-self: end;
        height: 40px;
        width: 40px;
        background-color: transparent;
        border: 1px solid var(--primary-rose-red);
        color: var(--primary-rose-red);
    }

    button.btn_prev{
        margin-right: 13rem;
    }

    button.btn_next{
        margin-right: 10rem;
    }

    .slick-initialized .slick-slide{
        padding-bottom: 4rem;
    }

    .purple-night{
        box-shadow: var(--box-shadow-purple);
    }

    .rose-red{
        box-shadow: var(--box-shadow-red);
    }

    .dark-grey {
        box-shadow: var(--box-shadow-dark);
    }

    .slick-disabled{
        opacity: .3;
    }


    @media screen and (max-width: 768px){
        button.slick_btn{
          display: none !important;
        }
    }
}

