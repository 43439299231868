
div.grid-image-right{
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    .col-left{
        grid-area: 1/1/2/3;
    }

    .col-right{
        grid-area: 1/2/2/3;
    }

    .description p{
        color: var(--primary-dark-grey);
    }


    @media screen and (max-width: 992px){
        grid-template-columns: auto;

        .col-left{
            grid-area: unset;
        }

        .col-right{
            grid-area: 1/1/2/2;
        }
    }
}