  
div.post-item-card-rn{
    position: relative;

    a.post-item-link{
        text-decoration: none;
        color: var(--primary-dark-grey);

        div.text-wrapper{
            .excerpt{
                font-size: var(--font-size-sm);
                // min-height: 50px;
            }
        }
    }    

    img.post-image {
        object-fit: cover;
        height: auto;
        aspect-ratio: 200/107;
    }

    .post-item-date{
        opacity: .7;
    }

    .post-item-header{
        font-size: 1.3rem;
        min-height: 75px;
    }

    .card-badge{
        position: absolute;
        right: 1rem;
        min-width: 200px;
        text-transform: uppercase;
        font-size: 1.5rem;
        color: var(--white);
        text-align: center;
        padding: 6px;
        font-weight: var(--bold);
    }
}


@media screen and (max-width:768px){
    div.post-item-card-rn{
        // img.post-image {
        //     height: 250px;
        // }

        .post-item-header{
            font-size: 1.2rem;
            min-height: auto;
        }
    }
}



@media screen and (max-width:576px){
    div.post-item-card-rn{
        // img.post-image {
        //     height: 150px;
        // }

        .post-item-header{
            font-size: .8rem;
        }

        &.vaerktoj-card{
            img.post-image {
                height: 90px;
            }
        }

    }

}