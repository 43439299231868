:root {
    /*------- Font sizes -------*/
    --font-size-sm: clamp(0.8rem, 0.17vw + 0.76rem, 0.89rem);
    
    --font-size-base: clamp(1rem, 0.34vw + 0.91rem, 1.19rem);

    // --font-size-md: clamp(1.25rem, 0.61vw + 1.1rem, 1.58rem);
    --font-size-md: clamp(1.2rem, 0.61vw + 1.1rem, 1.4rem);

    --font-size-lg: clamp(1.56rem, 1vw + 1.31rem, 2.11rem);

    --font-size-xl: clamp(1.95rem, 1.56vw + 1.56rem, 2.81rem);

    // --font-size-xxl: clamp(2.44rem, 2.38vw + 1.85rem, 3.75rem);
    --font-size-xxl: clamp(1.8rem, 2.38vw + 1.85rem, 2.5rem);
    
    --font-size-xxxl: clamp(3.05rem, 3.54vw + 2.17rem, 5rem);
  
    /*------- Font weights -------*/
    --bold: 600;
    --semibold: 500;
    --regular: 400;
  }