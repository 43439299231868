section.not-found-section{

    div.not-found-wrapper{
        display: grid;

        &>*{
            grid-area: 1/1/2/2;
        }
    }

    img.image{
        height: 80vh;
        object-fit: cover;
    }

    div.not-found-message{
        align-self: center;
        justify-self: center;
        color: var(--white);
        text-align: center;
    }

    h1.heading{
        font-size: 100px;
    }

    h3.text{
        font-size: 24px;
    }


    .btn__primary_lg {
        border: 1px solid var(--white);
        background-color: transparent;
        box-shadow: unset;

        &:hover{
            color: var(--white);
        }
    }


    @media screen and (max-width:992px){
        h1.heading{
            font-size: 70px;
        }
    }

}