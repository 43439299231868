

section.kursus-archive,
section.netvaerksmode-archive{

    a.post-item-link > * {
        grid-area: unset;
        
    }

    div.text-wrapper{
        color: var(--primary-dark-grey);
    }

    div.post-item-card .post-item-header {
        min-height: 75px;
    }


    @media screen and (max-width: 1200px){ 

        div.post-item-grid {
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
        }
    }


    @media screen and (max-width: 576px){ 

        div.post-item-grid {
            width: 100%;
            grid-template-columns: auto;
        }

        div.post-item-card .post-item-header {
            min-height: auto;
        }
    }
}

