
section.contact-us-section{

    div.form-wrapper{
        box-shadow: var(--box-shadow-form);
        margin-top: -6rem;
        background-color: var(--white);
        position: relative;
    }

    //CSS for Google Map
    div.acf-map {
        width: 100%;
        height: 42rem;
        border: #ccc solid 1px;
        margin: 20px 0;

        // Fixes potential theme css conflict.
        img {
            max-width: inherit !important;
        }
    }

    div.info-box-wrapper{
        position: absolute;
        top: 16rem;
        left: 6rem;
        width: 24rem;
        background-color: var(--white);
        
    }

    div.info-box{
        background-color: var(--white);
        
        .info-box-header{
            color: var(--primary-rose-red);
            font-weight: var(--bold);
        }

        .info-box-label{
            font-weight: var(--bold);
        }

        .info-box-text{
            font-size: .9rem;
        }
    }

    .btn__primary_sm{

        svg {
            width: 8%;
            margin-left: 10px;
        }

        &:hover{
            svg{
                transform: unset;
            }
        }

        &::after{
            width: unset;
        }
    
    }
    
 
    form{

        input{
            border: none;
            border-bottom: 1px solid var(--primary-rose-red);
            color: var(--primary-dark-grey);

            &:focus{
                outline: none; 
                background-color: var(--primary-light-grey);
            }
        }

        label{
            color: var(--primary-rose-red);
            width: 100%;

            a{
                color: var(--primary-rose-red);  
            }
        }

        textarea{
            width: 100%;
            outline: none; 
            border: 1px solid var(--primary-rose-red);
            color: var(--primary-dark-grey);
        }

        // ----------- Contact form 7 ------ //

        div.input-w-100{
            input{
                width: 100%;
            }
        }

        .btn__primary_lg-wrapper{
            input{
                color: var(--white);

                &:hover{
                    color: var(--primary-rose-red);
                }
            }
        }

        .wpcf7-spinner{
            display: none;
        } 

        // ----------- checkbox ------ //

        input[type="checkbox"] {
            width: 1.2rem;
            height: 1.2rem;
            border: 1px solid var(--primary-rose-red);
            background: hsl(200 50% 100%);
            margin: 0;
            padding: 0;
            display: inline-grid;
            outline: none;
            transition: 0.2s;
        }
    
        input[type="checkbox"] {
            --border-radius: 0.3rem;
        }
    
        @supports (-webkit-appearance: none) or (appearance: none) {
            input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            }
        
            input[type="checkbox"]:checked {
            background: var(--primary-rose-red);
            width: 1.2rem;
            height: 1.2rem;
            }
        
            input[type="checkbox"]::after {
            content: "";
            width: 0.3rem;
            height: 0.7rem;
            border-radius: 0;
            border: 2px solid var(--white);
            border-top: 0;
            border-left: 0;
            transition: 0.3s;
            transform: translateY(-0.1rem) rotate(0deg);
            place-self: center;
            opacity: 0;
            }
        
            input[type="checkbox"]:checked::after {
            opacity: 1;
            transform: translateY(-0.1rem) rotate(45deg);
            }
        }

        // ----------- checkbox ends ------ //



    }

    .wpcf7 form.sent .wpcf7-response-output {
        border: 1px solid var(--primary-rose-red);
    }


    @media screen and (max-width: 768px){

        div.info-box-wrapper{
            left: 2rem;
        }

    }


    @media screen and (max-width: 576px){
        div.acf-map {
            height: 26rem;
        }

        div.info-box-wrapper{
            top: 0;
            left: 0;
            margin-top: -90px;
            position: relative;
            max-width: 95%;
            box-shadow: var(--box-shadow-form);
        }

        div.form-wrapper{
            margin-top: 24rem;
            background-color: var(--white);
        }
    }

}
