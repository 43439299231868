
section.vaerktoj-subcategory{

    .rose-red,
    .green-sheen,
    .dark-grey{
        color: var(--white);
    }

    .pink{
        color: var(--primary-rose-red);
    }
}