div#download-report{

    div.modal-content{
        padding: 2rem;
        margin-top: 10rem;
        background-color: var(--primary-rose-red);
    }

    button.download-report-close{
        background-image: url(../img/icons/modal-close-btn.svg);
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        top: 25px;
        right: 25px;
        position: absolute;
        background-size: cover;
        cursor: pointer;
        background-color: transparent;
        border: none;
        outline: none;
        transition: .1s;

        &:hover{
            transform: scale(1.1);
        }
    }

    a {
        color: var(--white);
    }

    form{
        color: var(--white);
    }

    div.modal-group{
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
    }

    label {
        width: 100%;
    }

    input[type="text"],
    input[type="email"]{
        background-color: transparent !important;
        background: var(--primary-rose-red);
        border: none;
        border-bottom: 1px solid var(--white);
        color: var(--white);
        width: 100%;

        &:focus{
            outline: none;
            box-shadow: none;
        }
    }

    // ----------- checkbox ------ //

    input[type="checkbox"] {
        width: 1.2rem;
        height: 1.2rem;
        border: 1px solid var(--white);
        // background: hsl(200 50% 100%);
        margin: 0;
        padding: 0;
        display: inline-grid;
        outline: none;
        transition: 0.2s;
    }

    input[type="checkbox"] {
        --border-radius: 0.3rem;
    }

    @supports (-webkit-appearance: none) or (appearance: none) {
        input[type="checkbox"] {
        -webkit-appearance: none;
        -moz-appearance: none;
        }
    
        input[type="checkbox"]:checked {
        background: var(--white);
        width: 1.2rem;
        height: 1.2rem;
        }
    
        input[type="checkbox"]::after {
        content: "";
        width: 0.3rem;
        height: 0.7rem;
        border-radius: 0;
        border: 2px solid var(--primary-rose-red);
        border-top: 0;
        border-left: 0;
        transition: 0.3s;
        transform: translateY(-0.1rem) rotate(0deg);
        place-self: center;
        opacity: 0;
        }
    
        input[type="checkbox"]:checked::after {
        opacity: 1;
        transform: translateY(-0.1rem) rotate(45deg);
        }
    }

      // ----------- checkbox ends ------ //

    .btn__primary_sm{
        border: 1px solid var(--white);

        &::after{
            filter: unset;
        }

        &:hover{
            color: var(--white);
        }
    }
}